import "./shared/polyfills";

import { createRoot } from "react-dom/client";
import createSagaMiddleware from "redux-saga";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { ErrorBoundary, Provider as RollbarProvider } from "@rollbar/react";

import { default as Widget } from "./components/masamune-widget/index";
import rootSaga from "./sagas/index";
import { rootReducer } from "./reducers/root-reducer";
import { MonitoringNotificationsService } from "./services/monitoring-notification-service";
import { logWidgetEvent } from "./actions/widget-actions";
import supportedBrowsers from "../supportedBrowsers";
import { loggerMiddleware } from "./middlewares/loggerMiddleware";

import TailWindStyles from "./tailwind.css";
import GlobalStyles from "./styles.scss";
import FastlinkStyles from "./fastlink.scss";

const rollbar = MonitoringNotificationsService("rollbar");

const sagaOptions = { context: { rollbar: rollbar } };

export const setupStoreAndSagas = (preloadedState) => {
  const sagaMiddleware = createSagaMiddleware(sagaOptions);

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
      })
        .prepend(sagaMiddleware)
        .prepend(loggerMiddleware);
    },
    ...preloadedState,
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

class NinjaFetchWidget {
  static el;
  static processingTimeoutThresholdInSec = 300;
  static rootElem;

  static mount(params) {
    const store = setupStoreAndSagas();
    const defaultCallback = () => {};

    let userOnSuccess = params["onSuccess"] || defaultCallback;
    let userOnError = params["onError"] || defaultCallback;
    let userOnClose = params["onClose"] || defaultCallback;
    let accessToken = params["access_token"];
    let unmountOnCompletion = params["unmountOnCompletion"];

    if (userOnSuccess && typeof userOnSuccess !== "function") {
      throw new Error("onSuccess should be a function");
    }
    if (userOnError && typeof userOnError !== "function") {
      throw new Error("onError should be a function");
    }
    if (userOnClose && typeof userOnClose !== "function") {
      throw new Error("onClose should be a function");
    }
    if (unmountOnCompletion && typeof unmountOnCompletion !== "boolean") {
      throw new Error("unmountOnCompletion should be a boolean value");
    }

    const onSuccessWrapper = (ctx) => {
      const eventPayload = {
        eventPayload: {
          eventCode: "ON_SUCCESS_CALLBACK_CALLED",
          details: ctx,
        },
      };

      store.dispatch(
        logWidgetEvent({ eventPayload, accessToken, vendor: "masamune" })
      );

      try {
        window.parent.postMessage({ onSuccess: ctx }, "*");
      } catch (e) {
        console.error(e);
      }

      return userOnSuccess(ctx);
    };

    const onErrorWrapper = (ctx) => {
      const eventPayload = {
        eventPayload: {
          eventCode: "ON_ERROR_CALLBACK_CALLED",
          details: ctx,
        },
      };

      store.dispatch(
        logWidgetEvent({ eventPayload, accessToken, vendor: "masamune" })
      );

      try {
        window.parent.postMessage({ onError: ctx }, "*");
      } catch (e) {
        console.error(e);
      }

      return userOnError(ctx);
    };

    const onCloseWrapper = (ctx) => {
      const eventPayload = {
        eventPayload: {
          eventCode: "ON_CLOSE_CALLBACK_CALLED",
          details: ctx,
        },
      };

      store.dispatch(
        logWidgetEvent({ eventPayload, accessToken, vendor: "masamune" })
      );

      try {
        window.parent.postMessage({ onClose: ctx }, "*");
      } catch (e) {
        console.error(e);
      }

      return userOnClose(ctx);
    }

    // wrap callbacks and set params
    params["onSuccess"] = onSuccessWrapper;
    params["onError"] = onErrorWrapper;
    params["onClose"] = onCloseWrapper;

    const outdatedUserAgent = (
      <>
        <div id="masamuneWidget" className="flex items-center justify-center">
          <div
            id="ninja-fetch-widget"
            className="flex justify-center flex-col w-full max-w-[400px] m-5"
          >
            <p
              id="ninja-fetch-widget__browser_outdated_message"
              className="text-center mb-10"
            >
              Your browser is outdated. Please update your browser or use other
              device
            </p>
          </div>
        </div>
        <style type="text/css">{TailWindStyles}</style>
        <style type="text/css">{GlobalStyles}</style>
      </>
    );

    const wrapped = (
      <Provider store={store}>
        <ErrorBoundary>
          <div id="masamuneWidget" className="flex items-center justify-center">
            <Widget {...params} />
          </div>
        </ErrorBoundary>
        <style type="text/css">{TailWindStyles}</style>
        <style type="text/css">{GlobalStyles}</style>
      </Provider>
    );

    const component = rollbar.serviceConfig ? (
      <RollbarProvider config={rollbar.serviceConfig}>
        {wrapped}
      </RollbarProvider>
    ) : (
      wrapped
    );

    function doRender() {
      // Validate params
      let containerId = params["container_id"];
      let accessToken = params["access_token"];
      let consumerData = params["consumer_data"];

      if (NinjaFetchWidget.el) {
        throw new Error("EmbeddableWidget is already mounted, unmount first");
      }
      if (containerId === undefined) {
        throw new Error("Container id is missing");
      }
      if (accessToken === undefined) {
        throw new Error("Access token is missing");
      }
      if (consumerData === undefined) {
        throw new Error("Consumer data is missing");
      }

      // Add reference date to consumers data defaulting to the current
      const currentDate = new Date();
      params["consumer_data"]["reference_date"] = currentDate
        .toISOString()
        .split("T")[0];
      consumerData = params["consumer_data"];

      // Trim the consumer data fields if empty parameters are present
      Object.keys(consumerData).forEach((key) => {
        if (consumerData[key] === "") {
          delete consumerData[key];
        }
      });

      validateConsumerData(consumerData, params["product_request_id"]);

      const container = document.getElementById(containerId);

      if (container === null) {
        throw new Error("Cannot find container by the provided id");
      }

      const masamuneWidget = document.createElement("div");
      masamuneWidget.setAttribute("id", "masamuneWidget-wrapper");

      //  Create shadow dom host
      let shadow = container.shadowRoot;
      if (!container.shadowRoot) {
        shadow = container.attachShadow({ mode: "open" });
      }

      // // Attach shadow root to provided container
      // container.appendChild(shadow)

      // Create styles for fastlink
      const style = document.createElement("style");
      style.innerHTML = FastlinkStyles;
      const head = document.getElementsByTagName("head");
      head[0].appendChild(style);

      // Create element for fastlink
      const fastlinkContainer = document.createElement("div");
      fastlinkContainer.setAttribute("id", "container-fastlink");
      fastlinkContainer.style.display = "none";

      // Attach fastlink element to shadow dom
      shadow.appendChild(masamuneWidget);

      document.body.appendChild(fastlinkContainer);
      NinjaFetchWidget.rootElem = createRoot(masamuneWidget);

      if (supportedBrowsers.test(navigator.userAgent)) {
        NinjaFetchWidget.rootElem.render(component);
      } else {
        NinjaFetchWidget.rootElem.render(outdatedUserAgent);
      }

      NinjaFetchWidget.el = masamuneWidget;
    }

    let validateConsumerData = (consumerData, productRequestId) => {
      let validationRules = {
        consumer_id: {
          regex: new RegExp("^[a-zA-Z0-9-_.@]+$"),
          required: true,
        },
        first_name: {
          regex: false,
          required: !productRequestId,
        },
        last_name: {
          regex: false,
          required: !productRequestId,
        },
        reference_date: {
          regex: new RegExp(
            "([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))"
          ),
          required: false,
        },
      };
      let missingFields = [];
      let validationError = [];
      let consumerKeys = Object.keys(consumerData);
      let validationRulesEntries = Object.entries(validationRules);

      for (const [key, value] of validationRulesEntries) {
        if (value["required"] === true) {
          if (consumerKeys.indexOf(key) === -1) {
            missingFields.push(key);
            continue;
          }
        }

        if (value["regex"] !== false) {
          if (value["regex"].test(consumerData[key]) === false) {
            validationError.push(key);
          }
        }
      }

      const missingFieldErrorMessages = missingFields.join(", ");
      const validationErrorMessages = validationError.join(", ");

      if (missingFields.length > 0 || validationError.length > 0) {
        throw new Error(
          `Validation failed for: ${missingFieldErrorMessages}, ${validationErrorMessages} fields`
        );
      }
    };

    if (document.readyState === "complete") {
      doRender();
    } else {
      window.addEventListener("load", () => {
        doRender();
      });
    }
  }
  static unmount() {
    const fastlink = document.getElementById("container-fastlink");

    if (fastlink) {
      fastlink.remove();
    }

    if (!NinjaFetchWidget.el) {
      return;
    }

    NinjaFetchWidget.rootElem.unmount();
    NinjaFetchWidget.rootElem = null;
    NinjaFetchWidget.el.parentNode.removeChild(NinjaFetchWidget.el);
    NinjaFetchWidget.el = null;
  }
}

window.NinjaFetchWidget = NinjaFetchWidget;
