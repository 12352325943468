import {
  UCW_CONFIG_CANCEL_STATUS_POLLING,
  UCW_HANDLE_WIDGET_CLOSE,
  UCW_HANDLE_WIDGET_EVENT,
  UCW_HANDLE_WIDGET_ERROR,
  UCW_HANDLE_WIDGET_SUCCESS,
  UCW_CONNECTION_UPDATE,
  UCW_SET_WIDGET_CONFIGURATION,
  UCW_WIDGET_INIT,
  UCW_WIDGET_LOADING,
} from "../constants/ucw-widget";

export const cancelUcwWidgetConfigPolling = () => {
  return {
    type: UCW_CONFIG_CANCEL_STATUS_POLLING,
  };
};

export const handleWidgetSuccessCallbackAction = ({
  vendorResponse,
  connectionId,
  productRequestId,
}) => {
  return {
    type: UCW_HANDLE_WIDGET_SUCCESS,
    payload: { vendorResponse, connectionId, productRequestId },
  };
};

export const handleWidgetCloseCallbackAction = ({ eventPayload }) => {
  return {
    type: UCW_HANDLE_WIDGET_CLOSE,
    payload: { eventPayload },
  };
};

export const handleWidgetEventCallbackAction = ({ eventPayload }) => {
  return {
    type: UCW_HANDLE_WIDGET_EVENT,
    payload: { eventPayload },
  };
};

export const handleWidgetErrorCallbackAction = ({ eventPayload }) => {
  return {
    type: UCW_HANDLE_WIDGET_ERROR,
    payload: { eventPayload },
  };
};

export const initializeUcwWidgetAction = ({
  activeConnections,
  productRequestId,
  institutionId,
}) => {
  return {
    type: UCW_WIDGET_INIT,
    payload: { activeConnections, productRequestId, institutionId },
  };
};

export const updateUcwConnectionAction = ({
  connectionId,
  vendorResponse,
  productRequestId,
  vendorInstitutionId,
  status,
}) => {
  return {
    type: UCW_CONNECTION_UPDATE,
    payload: {
      connectionId,
      vendorResponse,
      productRequestId,
      vendorInstitutionId,
      status,
    },
  };
};

export const setIsLoading = (isLoading) => {
  return {
    type: UCW_WIDGET_LOADING,
    payload: { loading: isLoading },
  };
};

export const setUcwWidgetConfigurationAction = (payload) => {
  const params = {
    authCode: payload.widget_configuration.auth,
    env: payload.widget_configuration.env,
    userId: payload.widget_configuration.user_id,
  };

  return {
    type: UCW_SET_WIDGET_CONFIGURATION,
    payload: params,
  };
};
