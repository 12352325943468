import { combineReducers } from "@reduxjs/toolkit";
import { AkoyaReducer } from "./akoya-reducer";
import { BankSearchReducer } from "./bank-search-reducer";
import { FlinksWidgetConfigurationReducer } from "./flinks-widget-configuration-reducer";
import { MasamuneWidgetConfigurationReducer } from "./masamune-widget-configuration-reducer";
import { SophtronWidgetConfigurationReducer } from "./sophtron-widget-configuration-reducer";
import { YodleeWidgetConfigurationReducer } from "./yodlee-widget-configuration-reducer";
import { UcwWidgetConfigurationReducer } from "./ucw-widget-configuration-reducer";

export const rootReducer = combineReducers({
  globalConfig: MasamuneWidgetConfigurationReducer,
  yodleeConfig: YodleeWidgetConfigurationReducer,
  sophtronConfig: SophtronWidgetConfigurationReducer,
  flinksConfig: FlinksWidgetConfigurationReducer,
  bankSelectionConfig: BankSearchReducer,
  akoyaConfig: AkoyaReducer,
  ucwConfig: UcwWidgetConfigurationReducer
});
