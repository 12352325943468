import { useSelector } from "react-redux";

const NoVendorsInSequenceMessage = () => {
  const erroredInstitutions = useSelector(
    (state) => state.globalConfig.erroredInstitutions
  );

  return (
    <p
      id="ninja-fetch-widget__no_vendors_in_sequence_message"
      className="text-center"
    >
      Connections to{" "}
      {erroredInstitutions
        ? erroredInstitutions.join(", ")
        : "the financial institution"}{" "}
      are experiencing issues.
      <br />
      <br />
      Please try again later.
    </p>
  );
};

export default NoVendorsInSequenceMessage;
