import {
  YODLEE_CONFIG_CANCEL_STATUS_POLLING,
  YODLEE_CONNECTION_UPDATE,
  YODLEE_HANDLE_YODLEE_WIDGET_CLOSE,
  YODLEE_HANDLE_YODLEE_WIDGET_ERROR,
  YODLEE_HANDLE_YODLEE_WIDGET_EVENT,
  YODLEE_HANDLE_YODLEE_WIDGET_SUCCESS,
  YODLEE_SET_YODLEE_WIDGET_CONFIGURATION,
  YODLEE_SHOULD_REUSE_CONNECTION,
  YODLEE_WIDGET_INIT,
  YODLEE_WIDGET_LOADING,
} from "../constants/yodlee-widget";

export const setYodleeWidgetConfigurationAction = (payload) => {
  const params = {
    accessToken: payload.widget_configuration.access_token,
    accessTokenExpiresAt: payload.widget_configuration.access_token_expires_at,
    vendorWidgetConfig: payload.widget_configuration,
  };

  return {
    type: YODLEE_SET_YODLEE_WIDGET_CONFIGURATION,
    payload: params,
  };
};

export const handleWidgetErrorCallbackAction = ({ eventPayload }) => {
  return {
    type: YODLEE_HANDLE_YODLEE_WIDGET_ERROR,
    payload: { eventPayload },
  };
};

export const setShouldReuseConnection = () => {
  return {
    type: YODLEE_SHOULD_REUSE_CONNECTION,
  };
};

export const setIsLoading = (isLoading) => {
  return {
    type: YODLEE_WIDGET_LOADING,
    payload: { loading: isLoading },
  };
};

export const handleWidgetSuccessCallbackAction = ({
  eventPayload,
  connectionId,
  productRequestId,
}) => {
  return {
    type: YODLEE_HANDLE_YODLEE_WIDGET_SUCCESS,
    payload: { eventPayload, connectionId, productRequestId },
  };
};

export const handleWidgetCloseCallbackAction = ({ eventPayload }) => {
  return {
    type: YODLEE_HANDLE_YODLEE_WIDGET_CLOSE,
    payload: { eventPayload },
  };
};

export const handleWidgetEventCallbackAction = ({ eventPayload }) => {
  return {
    type: YODLEE_HANDLE_YODLEE_WIDGET_EVENT,
    payload: { eventPayload },
  };
};

export const cancelYodleeWidgetConfigPolling = () => {
  return {
    type: YODLEE_CONFIG_CANCEL_STATUS_POLLING,
  };
};

export const updateYodleeConnectionAction = ({
  connectionId,
  vendorResponse,
  productRequestId,
  yodleeProviderId,
  status,
}) => {
  return {
    type: YODLEE_CONNECTION_UPDATE,
    payload: {
      connectionId,
      vendorResponse,
      productRequestId,
      yodleeProviderId,
      status,
    },
  };
};

export const initializeYodleeWidgetAction = ({
  productRequestId,
  institutionId,
}) => {
  return {
    type: YODLEE_WIDGET_INIT,
    payload: { productRequestId, institutionId },
  };
};
