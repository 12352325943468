import {
  call,
  getContext,
  put,
  select,
  spawn,
  takeEvery,
} from "redux-saga/effects";
import {
  UCW_CONNECTION_UPDATE,
  UCW_HANDLE_WIDGET_CLOSE,
  UCW_HANDLE_WIDGET_ERROR,
  UCW_HANDLE_WIDGET_EVENT,
  UCW_HANDLE_WIDGET_SUCCESS,
  UCW_WIDGET_INIT,
} from "../constants/ucw-widget";
import {
  cancelUcwWidgetConfigPolling,
  setIsLoading,
  setUcwWidgetConfigurationAction,
} from "../actions/ucw-widget-actions";
import {
  findOrCreateUcwConnection,
  updateUcwConnectionRequest,
} from "../shared/ucw-widget-utils";
import {
  closeModalsAndWidgets,
  logWidgetEvent,
  runProductRequestPolling,
  setCriticalIssueOccurred,
  setCurrentWidgetFlow,
  setDataPullProcessingTime,
  setSwitchToNextVendor,
  setUcwConnection,
  setWidgetConfiguration,
  setWidgetFlow,
  stopProductRequestPolling,
  toggleProductRequestProcessing,
  toggleWidgetRender,
} from "../actions/widget-actions";
import {
  getWidgetConfiguration,
  isProductRequestFrozen,
} from "../shared/masamune-widget-utils";
import { ApiErrorMessagesMapper } from "../services/api-client";
import { CONNECTION_FREEZE } from "../constants/masamune-widget";

const vendor = "ucw";

// Checks for already running pull to prevent duplicate pulls
const withProductRequestFreezeMiddleware = (worker) =>
  function* (action) {
    const globalConfig = yield select((state) => state.globalConfig);
    let requestFreeze = yield call(
      isProductRequestFrozen,
      globalConfig.productRequest?.product_request_id,
      globalConfig
    );

    if (requestFreeze) {
      yield put(setCurrentWidgetFlow(CONNECTION_FREEZE));
      return yield put(setIsLoading(false));
    }

    // If connection processing is not true, proceed with the original worker
    yield call(worker, action);
  };

function* processUcwWidgetCallbackErrorWorker(action) {
  const { eventPayload } = action.payload;

  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;
  const rollbar = yield getContext("rollbar");
  const { onError } = globalConfig.clientCallbackFunctions;

  const connectionUpdateParams = {
    connectionId: globalConfig.ucwConnection?.id,
    productRequestId: globalConfig.productRequest?.product_request_id,
    edgeClientId: globalConfig.edgeClientId,
    vendorInstitutionId: eventPayload?.providerId,
    status: "login_failed",
    accessToken: globalConfig.accessToken,
    vendorResponse: eventPayload,
  };

  try {
    yield put(logWidgetEvent({ eventPayload, accessToken, vendor }));
    yield put(setIsLoading(true));
    yield call(updateUcwConnectionRequest, connectionUpdateParams);
  } catch (e) {
    rollbar.error("Failed to process error callback for Ucw", e, {
      params: connectionUpdateParams,
    });

    yield call(
      onError,
      ApiErrorMessagesMapper(e, { isLastVendor: globalConfig.isLastVendor })
    );

    /// Unmount on authentication error
    if (e?.cause?.statusCode === 401) {
      return yield put(toggleWidgetRender());
    }
    /// Switch to next on bad requests and server errors
    if (
      e?.cause?.statusCode === 500 ||
      e?.cause?.statusCode === 422 ||
      e?.cause?.statusCode === 400
    ) {
      yield put(setSwitchToNextVendor());
      return yield put(setCriticalIssueOccurred());
    }
    /// Switch to the next vendor if the Ucw code itself trows some errors
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
    yield put(setWidgetConfiguration({ showModal: true }));
  }
}

function* processUcwWidgetCallbackSuccessWorker(action) {
  const { vendorResponse, connectionId, productRequestId } = action.payload;
  const {
    accessToken,
    clientCallbackFunctions: { onError, onSuccess },
    edgeClientId,
    isLastVendor,
    productRequest,
  } = yield select((state) => state.globalConfig);
  const rollbar = yield getContext("rollbar");

  yield put(
    logWidgetEvent({ eventPayload: vendorResponse, accessToken, vendor })
  );

  const connectionUpdateParams = {
    connectionId,
    productRequestId,
    edgeClientId,
    accessToken,
    vendorResponse,
    vendorInstitutionId: vendorResponse?.providerId,
    status: "login_success",
  };

  try {
    yield put(setDataPullProcessingTime());
    yield call(updateUcwConnectionRequest, connectionUpdateParams);
    yield put(toggleProductRequestProcessing(true));
    yield put(setWidgetFlow());
    // Start product request polling
    yield put(runProductRequestPolling({ productRequestId, accessToken }));
  } catch (e) {
    rollbar.error("Failed to process success callback for Ucw", e, {
      params: connectionUpdateParams,
    });
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
    yield put(stopProductRequestPolling());
    yield put(cancelUcwWidgetConfigPolling());
    yield call(onError, ApiErrorMessagesMapper(e, { isLastVendor }));
  }
  productRequest.ibv_report_status = "initiated"; // Workaround to fix the issue with the product request status on initial callback

  yield call(onSuccess, productRequest); // Show the product request ID to the client
}

function* processUcwWidgetCallbackEventWorker(action) {
  const { eventPayload } = action.payload;
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;

  yield put(logWidgetEvent({ eventPayload, accessToken, vendor }));
}

function* processUcwWidgetCallbackCloseWorker(action) {
  const { eventPayload } = action.payload;
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;

  yield put(logWidgetEvent({ eventPayload, accessToken, vendor }));

  if (globalConfig?.widgetConfiguration?.features?.ucwBankSelectionMode) {
    if (globalConfig.currIdx !== 0) {
      yield put(
        setWidgetConfiguration({
          currIdx: 0,
        })
      );
    }

    return;
  }

  if (globalConfig?.widgetConfiguration?.features?.bankSelection) {
    yield put(closeModalsAndWidgets());
    yield put(
      setWidgetConfiguration({
        retryWithBankSelection: true,
        showModal: true,
      })
    );
  } else {
    yield put(setSwitchToNextVendor());
    yield put(setWidgetConfiguration({ showModal: true }));
  }
}

// Middleware-enhanced workers
const processUcwWidgetCallbackCloseWorkerWithMiddleware =
  withProductRequestFreezeMiddleware(processUcwWidgetCallbackCloseWorker);
const processUcwWidgetCallbackSuccessWorkerWithMiddleware =
  withProductRequestFreezeMiddleware(processUcwWidgetCallbackSuccessWorker);
const processUcwWidgetCallbackEventWorkerWithMiddleware =
  withProductRequestFreezeMiddleware(processUcwWidgetCallbackEventWorker);
const processUcwWidgetCallbackErrorWorkerWithMiddleware =
  withProductRequestFreezeMiddleware(processUcwWidgetCallbackErrorWorker);

// Watcher saga for UCW widget callbacks
function* watchUcwWidgetCallbacks() {
  yield takeEvery(
    UCW_HANDLE_WIDGET_CLOSE,
    processUcwWidgetCallbackCloseWorkerWithMiddleware
  );
  yield takeEvery(
    UCW_HANDLE_WIDGET_SUCCESS,
    processUcwWidgetCallbackSuccessWorkerWithMiddleware
  );
  yield takeEvery(
    UCW_HANDLE_WIDGET_EVENT,
    processUcwWidgetCallbackEventWorkerWithMiddleware
  );
  yield takeEvery(
    UCW_HANDLE_WIDGET_ERROR,
    processUcwWidgetCallbackErrorWorkerWithMiddleware
  );
}

function* handleUcwConnectionUpdateWorker(action) {
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;
  const edgeClientId = globalConfig.edgeClientId;

  const connectionUpdateParams = {
    ...action.payload,
    accessToken,
    edgeClientId,
  };
  yield put(setIsLoading(true));
  yield call(updateUcwConnectionRequest, connectionUpdateParams);
  yield put(setIsLoading(false));
}

function* watchUcwConnectionUpdate() {
  yield takeEvery(UCW_CONNECTION_UPDATE, handleUcwConnectionUpdateWorker);
}

function* handleUcwWidgetInitWorker(action) {
  const { productRequestId, institutionId } = action.payload;

  const {
    accessToken,
    clientCallbackFunctions: { onError },
    edgeClientId,
    isLastVendor,
  } = yield select((state) => state.globalConfig);

  const rollbar = yield getContext("rollbar");

  try {
    yield put(setIsLoading(true));

    const connection = yield call(findOrCreateUcwConnection, {
      productRequestId,
      institutionId,
      edgeClientId,
      accessToken,
    });

    const widgetConfig = yield call(getWidgetConfiguration, {
      vendor,
      connectionId: connection.id,
      accessToken,
    });

    yield put(setUcwConnection(connection));
    yield put(setUcwWidgetConfigurationAction(widgetConfig));
    yield put(setIsLoading(false));
  } catch (e) {
    rollbar.error("Failed to init UCW widget", e, {
      params: action.payload,
    });

    yield call(onError, ApiErrorMessagesMapper(e, { isLastVendor }));

    /// Unmount on authentication error
    if (e?.cause?.statusCode === 401) {
      return yield put(toggleWidgetRender());
    }
    /// Switch to next on bad requests and server errors
    if (
      e?.cause?.statusCode === 500 ||
      e?.cause?.statusCode === 422 ||
      e?.cause?.statusCode === 400
    ) {
      yield put(setSwitchToNextVendor());
      return yield put(setCriticalIssueOccurred());
    }
    /// Switch to the next vendor if the Ucw code itself trows some errors
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
    yield put(setIsLoading(false));
  }
}

function* watchUcwWidgetInit() {
  yield takeEvery(UCW_WIDGET_INIT, handleUcwWidgetInitWorker);
}

export default function* ucwWidgetSagas() {
  yield spawn(watchUcwConnectionUpdate);
  yield spawn(watchUcwWidgetCallbacks);
  yield spawn(watchUcwWidgetInit);
}
