import {
  YODLEE_GET_WIDGET_CONFIGURATION,
  YODLEE_INIT_WIDGET,
  YODLEE_SET_YODLEE_WIDGET_CONFIGURATION,
  YODLEE_SHOULD_REUSE_CONNECTION,
  YODLEE_WIDGET_CONFIG_UPDATE,
  YODLEE_WIDGET_LOADING,
} from "../constants/yodlee-widget";

const initialState = {
  accessToken: null,
  accessTokenExpiresAt: null,
  vendorInstitutionId: "",
  providerAccountId: null,
  ibvConnection: null,
  shouldReuseExistingConnection: false,
  vendorWidgetConfig: {},
  loading: false,
};

export const YodleeWidgetConfigurationReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case YODLEE_GET_WIDGET_CONFIGURATION:
      return { ...state, ...action.payload };
    case YODLEE_WIDGET_LOADING:
      return { ...state, ...action.payload };
    case YODLEE_INIT_WIDGET:
      return { ...state, ...action.payload };
    case YODLEE_WIDGET_CONFIG_UPDATE:
      return { ...state, ...action.payload };
    case YODLEE_SET_YODLEE_WIDGET_CONFIGURATION:
      return { ...state, ...action.payload };
    case YODLEE_SHOULD_REUSE_CONNECTION:
      return { ...state, shouldReuseExistingConnection: true };
    default:
      return state;
  }
};
