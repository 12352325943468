import { useEffect, useState } from "react";

const useSophtronWidget = ({
  env,
  partner = "default",
  integrationKey,
  requestId,
  routingNumber,
  institutionId,
  userInstitutionId,
  onShow,
  onInit,
  onClose,
  onSelectBank,
  onLogin,
  onLoginSuccess,
  onMfa,
  onFinish,
  onError,
  createScriptTag = true,
}) => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    let script;
    if (createScriptTag) {
      script = document.createElement("script");

      script.src =
        "https://cdn.sophtron.com/sophtron-widget-loader-0.0.0.5.min.js";
      script.async = true;
      script.defer = true;
      script.onload = () => setReady(true);
      script.onerror = () => setError("Sophtron library could not be loaded!");

      document.body.appendChild(script);
    }

    return () => {
      if (window.sophtron.state.widgetFrame) {
        window.sophtron.destroy();
      }
      if (createScriptTag) {
        document.body.removeChild(script);
      }
    };
  }, [createScriptTag]);

  const init = (action = "Add") => {
    setActive(true);

    window.sophtron.init(
      action,
      {
        env: env,
        partner: partner,
        integration_key: integrationKey,
        request_id: requestId,
        institution_id: institutionId,
        routing_number: routingNumber,
        userInstitution_id: userInstitutionId,
        onShow: (data) => {
          onShow && onShow(data);
        },
        onInit: (data) => {
          onInit && onInit(data);
        },
        onClose: (data) => {
          onClose && onClose(data);
        },
        onSelectBank: (data) => {
          onSelectBank && onSelectBank(data);
        },
        onLogin: (data) => {
          onLogin && onLogin(data);
        },
        onLoginSuccess: (data) => {
          onLoginSuccess && onLoginSuccess(data);
        },
        onMfa: (data) => {
          onMfa && onMfa(data);
        },
        onFinish: (data) => {
          setData(data);
          onFinish && onFinish(data);
        },
        onError: (data) => {
          onError && onError(data);
        },
      },
      true
    );

    window.sophtron.show();
  };

  return {
    init,
    ready,
    error,
    data,
    active,
  };
};

export default useSophtronWidget;
