export const FLINKS_WIDGET_UPDATE_DELAY = 60000;

//ACTIONS
export const FLINKS_WIDGET_INIT = "FLINKS/WIDGET_INIT";
export const FLINKS_HANDLE_FLINKS_WIDGET_EVENT =
  "FLINKS/HANDLE_FLINKS_WIDGET_EVENT";
export const FLINKS_HANDLE_FLINKS_WIDGET_SUCCESS =
  "FLINKS/HANDLE_FLINKS_WIDGET_SUCCESS";
export const FLINKS_HANDLE_FLINKS_WIDGET_CLOSE =
  "FLINKS/HANDLE_FLINKS_WIDGET_CLOSE";
export const FLINKS_HANDLE_FLINKS_WIDGET_ERROR =
  "FLINKS/HANDLE_FLINKS_WIDGET_ERROR";
export const FLINKS_CONNECTION_UPDATE = "FLINKS/CONNECTION_UPDATE";
export const FLINKS_WIDGET_CONFIG_UPDATE = "FLINKS/WIDGET_CONFIG_UPDATE";
export const FLINKS_SET_FLINKS_WIDGET_CONFIGURATION =
  "FLINKS/SET_FLINKS_WIDGET_CONFIGURATION";
export const FLINKS_CONFIG_CANCEL_STATUS_POLLING =
  "FLINKS/CONFIG_CANCEL_STATUS_POLLING";
export const FLINKS_SHOULD_REUSE_CONNECTION = "FLINKS/SHOULD_REUSE_CONNECTION";
export const FLINKS_WIDGET_TOGGLE_SHOW = "FLINKS/WIDGET_TOGGLE_SHOW";
export const FLINKS_WIDGET_TOGGLE_LOADING = "FLINKS/WIDGET_TOGGLE_LOADING";
export const FLINKS_SET_VISIBLE = "FLINKS/SET_VISIBLE";

// Flinks events statuses
/*
export const APP_MOUNTED = 'APP_MOUNTED' //The internet connection was restored
export const APP_ONLINE	= 'APP_ONLINE' //The internet connection was restored
export const APP_OFFLINE = 'APP_OFFLINE' //The internet connection was lost
export const ACCOUNT_SELECTED  = 'ACCOUNT_SELECTED' //The user selects an account
export const COMPONENT_ACCEPT_CONSENT = 'COMPONENT_ACCEPT_CONSENT' //The user accepts to give consent
export const COMPONENT_ACCEPT_TERMS	= 'COMPONENT_ACCEPT_TERMS' //The user has checked the box to approve the terms and conditions linked in the URL termsUrl={https://example.com}.
export const COMPONENT_CLICK_RESET_PASSWORD = 'COMPONENT_CLICK_RESET_PASSWORD'	//The user clicks on the Reset Password button.
export const COMPONENT_CLICK_TERMS_URL	= 'COMPONENT_CLICK_TERMS_URL' //The user clicks on the terms and conditions link.
export const COMPONENT_DENY_CONSENT	= 'COMPONENT_DENY_CONSENT' //The user denies consent
export const COMPONENT_DENY_TERMS = 'COMPONENT_DENY_TERMS'	//The user unchecked the box on the left of the terms and conditions using the parameter termsUrl={https://example.com}.
export const COMPONENT_LOAD_ACCOUNT_SELECTION = 'COMPONENT_LOAD_ACCOUNT_SELECTION' //When Flinks Connect loads the account selection page. Contains metadata regarding the user's accounts (totalAccounts & displayedAccounts)
export const COMPONENT_LOAD_CONSENT	= 'COMPONENT_LOAD_CONSENT' //The consent page is loaded using the parameter consentEnable=true
export const COMPONENT_LOAD_CREDENTIAL	= 'COMPONENT_LOAD_CREDENTIAL' //The user is faced with a prompt for FI username and password.
export const COMPONENT_LOAD_CREDENTIAL_RETRY = 'COMPONENT_LOAD_CREDENTIAL_RETRY' //The user clicks on retry after an authorize error
export const COMPONENT_LOAD_INSTITUTION_SELECTOR = 'COMPONENT_LOAD_INSTITUTION_SELECTOR' //The FI list page is loaded
export const COMPONENT_LOAD_MFA	= 'COMPONENT_LOAD_MFA' //The MFA page is loaded. Contains metadata that indicates what MFA challenges the user received (mfaTypes)
export const COMPONENT_LOAD_MFA_RETRY = 'COMPONENT_LOAD_MFA_RETRY' //The user clicks on retry after a failed MFA challenge.error
export const DISABLED_INSTITUTION = 'DISABLED_INSTITUTION' //An Institution is disabled.
export const ENHANCED_MFA_HAS_QUESTIONS	= 'DISABLED_INSTITUTION' //When there is unanswered question using enhancedMFA=true
export const ENHANCED_MFA_NO_QUESTIONS	= 'ENHANCED_MFA_NO_QUESTIONS' //When there is no unanswered question using enhancedMFA=true
export const ENHANCED_MFA_SUCCESS = 'ENHANCED_MFA_SUCCESS' //Successfully completed the enhancedMFA flow using enhancedMFA=true
export const INSTITUTION_SELECTED = 'INSTITUTION_SELECTED'	//The user selected his desired Financial Institution.
export const INVALID_INSTITUTION = 'INVALID_INSTITUTION' //An institution is not valid.
export const INVALID_SECURITY_RESPONSE	= 'INVALID_SECURITY_RESPONSE' //The MFA challenge failed.
export const QUESTION_NOT_FOUND	= 'QUESTION_NOT_FOUND'//The MFA challenge is returned by the API. Contains metadata that indicates what MFA challenges the user received (mfaTypes)
export const RETRY_COUNT = 'RETRY_COUNT' //The authorization failed and the retry count is incremented. Staring at 0.
export const SKIP_ENHANCED_MFA = 'SKIP_ENHANCED_MFA' //The user skips the unanswered MFA question using skipEnhancedMFA=true
export const SUBMIT_ANSWER_MFA_QUESTIONS = 'SUBMIT_ANSWER_MFA_QUESTIONS' //The user submits the unanswered MFA questions
export const SUBMIT_CREDENTIAL = 'SUBMIT_CREDENTIAL' //The form is valid and the user clicks on continue.
export const SUBMIT_GET_MFA_QUESTIONS = 'SUBMIT_GET_MFA_QUESTIONS' //When Flinks Connect gets unanswered MFA questions using enhancedMFA=true
export const SUBMIT_MFA	= 'SUBMIT_MFA' //The user submits the MFA
*/

/* WIDGET EVENT CODES */

// Widget finished loading
export const APP_MOUNTED = "APP_MOUNTED";

// Success final state
export const REDIRECT = "REDIRECT"; //When the user successfully completed the connection and is redirected using the parameter redirectUrl={https://example.com}
// Unprocessable error codes
export const INSTITUTION_NOT_AVAILABLE = "INSTITUTION_NOT_AVAILABLE"; //An institution is not available.
export const SESSION_NONEXISTENT = "SESSION_NONEXISTENT"; //When the session expired.
export const SESSION_EXPIRED = "SESSION_EXPIRED"; //When the session expired.
export const MAXIMUM_RETRY_REACHED = "MAXIMUM_RETRY_REACHED"; //The maximum amount of retries is reached using the parameter maximumRetry={number}. It is set in the URL as a parameter.
export const RETRY_COUNT = "RETRY_COUNT"; //The authorization failed and the retry count is incremented. Staring at 0.

export const FLINKS_UNPROCESSABLE_ERROR_CODES = [
  SESSION_NONEXISTENT,
  SESSION_EXPIRED,
  MAXIMUM_RETRY_REACHED,
  INSTITUTION_NOT_AVAILABLE,
];

// Widget closed code
export const FLINKS_WIDGET_CLOSED = "COMPONENT_CLOSE_SESSION";
export const POPUP_CLOSED = "POPUP_CLOSED";
