import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const TermsAndConditions = () => {
  const { widgetConfiguration } = useSelector((state) => state.globalConfig);
  const tcDescription = widgetConfiguration?.texts?.tc_description || "";

  return (
    <div className="text-center ninja-fetch-widget__terms-and-conditions">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          a: ({ href, children }) => (
            <a
              href={href}
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 underline hover:text-blue-700"
            >
              {children}
            </a>
          ),
          p: ({ children }) => <p className="inline">{children}</p>,
        }}
      >
        {tcDescription}
      </ReactMarkdown>
    </div>
  );
};

export default TermsAndConditions;
