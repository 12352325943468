import {
  CLEAR_SEARCH_RESULTS,
  LOG_BANK_SEARCH_EVENT,
  SET_FOUND_INSTITUTIONS,
  SET_INSTITUTION,
  SET_NOT_SUPPORTED_INSTITUTION,
  SET_QUERY,
  SET_TOP_INSTITUTIONS,
  TOGGLE_SEARCH_SCREEN,
  TOGGLE_SEARCH_SCREEN_LOADING,
} from "../constants/bank-selection";

export const setFoundInstitutions = (institutions) => {
  return {
    type: SET_FOUND_INSTITUTIONS,
    payload: institutions,
  };
};

export const clearSearchResults = () => {
  return {
    type: CLEAR_SEARCH_RESULTS,
    payload: { foundInstitutions: [], query: "" },
  };
};

export const setNotSupportedInstitution = (institution) => {
  return {
    type: SET_NOT_SUPPORTED_INSTITUTION,
    payload: institution,
  };
};

export const logBankSelectionEvent = (eventPayload) => {
  return {
    type: LOG_BANK_SEARCH_EVENT,
    payload: eventPayload,
  };
};

export const setTopInstitutions = (institutions) => {
  return {
    type: SET_TOP_INSTITUTIONS,
    payload: institutions,
  };
};

export const setInstitution = (institution) => {
  return {
    type: SET_INSTITUTION,
    payload: institution,
  };
};

export const toggleBankSearchScreen = (showScreen) => {
  return {
    type: TOGGLE_SEARCH_SCREEN,
    payload: showScreen,
  };
};

export const toggleBankSearchScreenLoading = () => {
  return {
    type: TOGGLE_SEARCH_SCREEN_LOADING,
  };
};

export const setQuery = (queryValue) => {
  return {
    type: SET_QUERY,
    payload: queryValue,
  };
};
