import ApiClient from "../services/api-client";
import {
  APP_MOUNTED,
  FLINKS_UNPROCESSABLE_ERROR_CODES,
  RETRY_COUNT,
  FLINKS_WIDGET_CLOSED,
  REDIRECT,
  POPUP_CLOSED,
} from "../constants/flinks-widget";

export const findOrCreateFlinksConnection = async ({
  productRequestId,
  institutionId,
  edgeClientId,
  accessToken,
}) => {
  const params = {
    edge_client_id: edgeClientId,
    product_request_id: productRequestId,
    institution_id: institutionId,
    vendor: "flinks",
  };
  return await ApiClient.findOrCreateIbvConnection(params, accessToken);
};

export const updateFlinksConnectionRequest = async ({
  connectionId,
  vendorResponse,
  productRequestId,
  vendorInstitutionId,
  edgeClientId,
  accessToken,
  status,
}) => {
  const params = {
    vendor_response: vendorResponse,
    edge_client_id: edgeClientId,
    product_request_id: productRequestId,
    vendor_institution_id: vendorInstitutionId,
    status: status,
    vendor: "flinks",
  };

  return await ApiClient.updateIbvConnection(connectionId, params, accessToken);
};

export const buildFlinksUrl = (baseUrl, institutionId, params = {}) => {
  if (institutionId) {
    baseUrl = baseUrl + "/Credential/" + institutionId;
  } // Prefill institution
  let url = new URL(baseUrl);

  for (let [key, value] of Object.entries(params)) {
    url.searchParams.set(key, value);
  }

  return url.href;
};

export const successCode = (eventCode) => {
  return eventCode === REDIRECT;
};

export const loginFailedCode = (eventCode) => {
  return eventCode === RETRY_COUNT;
};

export const errorCode = (eventCode) => {
  return (
    loginFailedCode(eventCode) ||
    FLINKS_UNPROCESSABLE_ERROR_CODES.indexOf(eventCode) !== -1
  );
};

export const widgetLoaded = (eventCode) => {
  return eventCode === APP_MOUNTED;
};

export const closeCode = (eventCode) => {
  return eventCode === FLINKS_WIDGET_CLOSED;
};


export const oauthCloseCode = (eventCode) => {
  return eventCode === POPUP_CLOSED;
}
