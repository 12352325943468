import ApiClient from "../services/api-client";

export const updateAkoyaConnectionRequest = async ({
  connectionId,
  vendorResponse,
  productRequestId,
  vendorInstitutionId,
  edgeClientId,
  accessToken,
  status,
}) => {
  const params = {
    vendor_response: vendorResponse,
    edge_client_id: edgeClientId,
    product_request_id: productRequestId,
    vendor_institution_id: vendorInstitutionId,
    status: status,
    vendor: "akoya",
  };

  return await ApiClient.updateIbvConnection(connectionId, params, accessToken);
};

export const findOrCreateAkoyaConnection = async ({
  productRequestId,
  institutionId,
  edgeClientId,
  accessToken,
}) => {
  const params = {
    edge_client_id: edgeClientId,
    product_request_id: productRequestId,
    institution_id: institutionId,
    vendor: "akoya",
  };
  return await ApiClient.findOrCreateIbvConnection(params, accessToken);
};
