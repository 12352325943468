import { useSelector } from "react-redux";
import Footer from "./footer";
import MasamuneWidgetBody from "./masamune-widget-body";
import TermsAndConditions from "./terms-and-conditions";
import { REQUEST_COMPLETE } from "../../constants/masamune-widget";
import RequestCompleteMessage from "./request-complete-message";

const OldFlow = (props) => {
  const { flow, productRequestProcessing } = useSelector(
    (state) => state.globalConfig
  );

  if (flow === REQUEST_COMPLETE)
    return (
      <>
        <RequestCompleteMessage />
        <Footer />
      </>
    );

  return (
    <>
      {!productRequestProcessing && <TermsAndConditions />}

      <MasamuneWidgetBody
        {...props}
        institution={props.institution}
        vendorName={props.vendorName}
      />
      <Footer />
    </>
  );
};

export default OldFlow;
