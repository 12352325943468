import {
  FLINKS_SET_FLINKS_WIDGET_CONFIGURATION,
  FLINKS_SET_VISIBLE,
  FLINKS_SHOULD_REUSE_CONNECTION,
  FLINKS_WIDGET_CONFIG_UPDATE,
  FLINKS_WIDGET_INIT,
  FLINKS_WIDGET_TOGGLE_LOADING,
  FLINKS_WIDGET_TOGGLE_SHOW,
} from "../constants/flinks-widget";

const initialState = {
  widgetUrl: null,
  showWidget: false,
  loading: false,
  display: "none",
  zIndex: -10000,
};

export const FlinksWidgetConfigurationReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FLINKS_WIDGET_TOGGLE_SHOW:
      return { ...state, showWidget: !state.showWidget };
    case FLINKS_SET_VISIBLE:
      return { ...state, display: "block", zIndex: 10000 };
    case FLINKS_WIDGET_TOGGLE_LOADING:
      return { ...state, loading: !state.loading };
    case FLINKS_WIDGET_INIT:
      return { ...state, ...action.payload };
    case FLINKS_WIDGET_CONFIG_UPDATE:
      return { ...state, ...action.payload };
    case FLINKS_SET_FLINKS_WIDGET_CONFIGURATION:
      return { ...state, ...action.payload };
    case FLINKS_SHOULD_REUSE_CONNECTION:
      return { ...state, shouldReuseExistingConnection: true };
    default:
      return state;
  }
};
