import {
  UCW_SET_WIDGET_CONFIGURATION,
  UCW_WIDGET_INIT,
  UCW_WIDGET_CONFIG_UPDATE,
  UCW_WIDGET_LOADING,
  UCW_SEARCH_MODE,
} from "../constants/ucw-widget";

const initialState = {
  authCode: null,
  routingNumber: null,
  vendorInstitutionId: null,
  userInstitutionId: null,
  env: "preview",
  selectedInstitutionId: null,
  shouldReuseExistingConnection: false,
  loading: false,
  userId: null,
};

export const UcwWidgetConfigurationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UCW_SET_WIDGET_CONFIGURATION:
      return { ...state, ...payload };
    case UCW_WIDGET_CONFIG_UPDATE:
      return { ...state, ...payload };
    case UCW_WIDGET_INIT:
      return { ...state, ...payload };
    case UCW_WIDGET_LOADING:
      return { ...state, ...payload };
    case UCW_SEARCH_MODE:
      return { ...state, ...payload };
    default:
      return state;
  }
};
