const ProcessingMessage = () => {
  return (
    <p id="ninja-fetch-widget__processing_message" className="text-center">
      Your account is connecting and this process can take up to a few minutes.
      Please do not click on anything or leave this page while we complete the
      process. Thanks!
    </p>
  );
};

export default ProcessingMessage;
