import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { logBankSelectionEvent } from "../../actions/bank-selection-actions";
import {
  handleWidgetCloseCallbackAction,
  handleWidgetErrorCallbackAction,
  handleWidgetEventCallbackAction,
  handleWidgetSuccessCallbackAction,
  initializeFlinksWidgetAction,
  setFlinksWidgetVisible,
  toggleFlinksWidgetLoading,
  toggleFlinksWidgetShow,
  updateFlinksConnectionAction,
} from "../../actions/flinks-widget-actions";
import { ApiErrorMessagesMapper } from "../../services/api-client";
import LoadingButton from "../shared/loading-button";

import { setRetryMode, toggleWidgetRender } from "../../actions/widget-actions";
import {
  closeCode,
  errorCode,
  oauthCloseCode,
  successCode,
  widgetLoaded,
} from "../../shared/flinks-widget-utils";
import {
  logBusinessClicked,
  logBusinessLoginFailed,
  logBusinessLoginSucceeded,
} from "../../actions/business-actions";
import { MonitoringNotificationsService } from "../../services/monitoring-notification-service";
import ConnectMyAccountButton from "../masamune-widget/connect-my-account-button";

const rollbar = MonitoringNotificationsService("rollbar");

export const FlinksWidget = (props) => {
  const dispatch = useDispatch();
  const flinksConfig = useSelector((state) => state.flinksConfig);
  const shouldRender = useSelector((state) => state.globalConfig.shouldRender);
  const isLastVendor = useSelector((state) => state.globalConfig.isLastVendor);
  const sequenceHistory = useSelector(
    (state) => state.globalConfig.sequenceHistory
  );
  const flinksConnection = useSelector(
    (state) => state.globalConfig.flinksConnection
  );
  const widgetConfiguration = useSelector(
    (state) => state.globalConfig.widgetConfiguration
  );
  const retryMode = useSelector((state) => state.globalConfig.retryMode);
  const processing = useSelector((state) => state.globalConfig.processing);

  const consumer = useSelector((state) => state.globalConfig.consumer);
  const productRequest = useSelector(
    (state) => state.globalConfig.productRequest
  );

  const existingProductRequestId = props.productRequestId;
  const institution = props.institution;

  const initWidget = () => {
    const widgetInitParams = {
      productRequestId: existingProductRequestId,
      institution: institution,
    };

    dispatch(initializeFlinksWidgetAction(widgetInitParams));
  };

  useEffect(() => {
    try {
      if (shouldRender) {
        initWidget();
      }
    } catch (e) {
      rollbar.error("Failed to init flinks widget", e);
      props.onError(ApiErrorMessagesMapper(e, { isLastVendor: isLastVendor }));

      if (e.cause.statusCode === 401) {
        dispatch(toggleWidgetRender());
      }
    }
  }, []);

  useEffect(() => {
    if (flinksConfig?.widgetUrl && flinksConnection?.id) {
      dispatch(
        logBankSelectionEvent({
          eventPayload: {
            eventCode: "FLINKS_WIDGET_LOADED",
          },
        })
      );

      if (
        (widgetConfiguration?.features?.ucwBankSelectionMode &&
          sequenceHistory.length > 1) ||
        retryMode
      ) {
        dispatch(setRetryMode(false));
        return loadFlinksWidget();
      }
    }
  }, [flinksConfig?.widgetUrl, flinksConnection?.id]);

  useEffect(() => {
    window.addEventListener("message", flinksEventsHandler);

    return () => {
      window.removeEventListener("message", flinksEventsHandler);
    };
  }, [flinksConnection]);

  const flinksEventsHandler = (e) => {
    performEventHandling(e.data);
  };

  const performEventHandling = (e) => {
    const eventCode = e.step;

    if (eventCode === undefined || eventCode === null) {
      return;
    }

    if (successCode(eventCode)) {
      dispatch(
        logBusinessLoginSucceeded({
          consumer_id: consumer?.client_consumer_id,
          product_request_id: existingProductRequestId,
          connection_id: flinksConnection?.id,
          vendor_name: flinksConnection?.vendor_name,
          event_details: e,
          vendor_institution_id: e?.institution,
        })
      );

      return dispatch(
        handleWidgetSuccessCallbackAction({
          vendorResponse: e,
          connectionId: flinksConnection?.id,
          productRequestId: existingProductRequestId,
        })
      );
    }

    if (widgetLoaded(eventCode)) {
      dispatch(toggleFlinksWidgetLoading(false));
    }

    if (errorCode(eventCode)) {
      dispatch(
        logBusinessLoginFailed({
          consumer: consumer?.client_consumer_id,
          product_request_id: existingProductRequestId,
          connection_id: flinksConnection?.id,
          vendor_name: flinksConnection?.vendor_name,
          event_details: e,
          vendor_institution_id: e?.institution,
        })
      );

      return dispatch(handleWidgetErrorCallbackAction({ eventPayload: e }));
    }

    if (closeCode(eventCode) || oauthCloseCode(eventCode)) {
      return dispatch(handleWidgetCloseCallbackAction({ eventPayload: e }));
    }

    dispatch(handleWidgetEventCallbackAction({ eventPayload: e }));
  };

  const handleActiveBtnClick = (e, consumer, productRequest) => {
    e.preventDefault();

    dispatch(
      logBusinessClicked({
        consumer_id: consumer?.client_consumer_id,
        product_request_id: productRequest?.id,
        vendor_name: "flinks",
        institution_id: institution?.institution_id,
      })
    );

    dispatch(
      logBankSelectionEvent({
        eventPayload: {
          eventCode: "FLINKS_CONNECT_BTN_CLICKED",
        },
      })
    );

    loadFlinksWidget();
  };

  const activeBtn = () => {
    return (
      <ConnectMyAccountButton
        handleActiveBtnClick={(e) => {
          handleActiveBtnClick(e, consumer, productRequest);
        }}
      />
    );
  };

  const loadFlinksWidget = () => {
    dispatch(
      updateFlinksConnectionAction({
        connectionId: flinksConnection?.id,
        productRequestId: existingProductRequestId,
        status: "login_in_progress",
      })
    );

    dispatch(
      logBankSelectionEvent({
        eventPayload: {
          eventCode: "FLINKS_WIDGET_LOADED",
        },
      })
    );

    dispatch(toggleFlinksWidgetLoading(false));
    dispatch(setFlinksWidgetVisible(false));
    dispatch(toggleFlinksWidgetShow());
  };

  if (flinksConfig.showWidget) {
    return (
      <div className="ninja-fetch-widget__body flex justify-center">
        {!widgetConfiguration?.features?.bankSelection &&
          flinksConfig.loading && <LoadingButton />}
        <div id={"flinks-widget"}>
          <iframe
            title={"Flinks widget"}
            className="flinksconnect 2xl:w-2/4 xl:w-2/4 lg:w-2/4 w-full h-full"
            height="720"
            src={flinksConfig.widgetUrl}
            style={{
              display: flinksConnection.display,
              zIndex: flinksConfig.zIndex,
            }}
          ></iframe>
        </div>
      </div>
    );
  }

  if (!widgetConfiguration?.features?.bankSelection) {
    return (
      <div className="ninja-fetch-widget__body flex justify-center">
        {flinksConfig?.widgetUrl && !processing && !flinksConfig.loading ? (
          activeBtn()
        ) : (
          <LoadingButton />
        )}
      </div>
    );
  }
};

export default connect(null)(FlinksWidget);
