import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import LoadingButton from "../shared/loading-button";
import { logBankSelectionEvent } from "../../actions/bank-selection-actions";
import { toggleWidgetRender, setRetryMode } from "../../actions/widget-actions";
import {
  handleWidgetCloseCallbackAction,
  handleWidgetErrorCallbackAction,
  handleWidgetEventCallbackAction,
  handleWidgetSuccessCallbackAction,
  initializeYodleeWidgetAction,
  updateYodleeConnectionAction,
} from "../../actions/yodlee-widget-actions";
import useYodleeFastlink from "../../hooks/use-yodlee-fastlink";
import { ApiErrorMessagesMapper } from "../../services/api-client";
import {
  resolveVendorWidgetConfig,
  showFastlink,
} from "../../shared/yodlee-widget-utils";
import {
  logBusinessClicked,
  logBusinessLoginFailed,
  logBusinessLoginSucceeded,
} from "../../actions/business-actions";
import { MonitoringNotificationsService } from "../../services/monitoring-notification-service";
import ConnectMyAccountButton from "../masamune-widget/connect-my-account-button";

const rollbar = MonitoringNotificationsService("rollbar");

const YodleeWidget = (props) => {
  const dispatch = useDispatch();
  const yodleeConfig = useSelector((state) => state.yodleeConfig);

  const shouldRender = useSelector((state) => state.globalConfig.shouldRender);
  const isLastVendor = useSelector((state) => state.globalConfig.isLastVendor);
  const intentUrl = useSelector((state) => state.globalConfig.intentUrl);
  const sequenceHistory = useSelector(
    (state) => state.globalConfig.sequenceHistory
  );
  const widgetConfiguration = useSelector(
    (state) => state.globalConfig.widgetConfiguration
  );
  const retryMode = useSelector((state) => state.globalConfig.retryMode);
  const yodleeConnection = useSelector(
    (state) => state.globalConfig.yodleeConnection
  );

  const loading = useSelector((state) => state.yodleeConfig.loading);

  const consumer = useSelector((state) => state.globalConfig.consumer);
  const productRequest = useSelector(
    (state) => state.globalConfig.productRequest
  );
  const existingProductRequestId = props.productRequestId;
  const institution = props.institution;

  const yodleeWidgetConfiguration = resolveVendorWidgetConfig(
    yodleeConfig,
    intentUrl,
    yodleeConnection,
    institution,
    props.ignoreExistingConnection
  );

  const onSuccessHandler = (data) => {
    window.fastlink.close();

    dispatch(
      logBusinessLoginSucceeded({
        consumer_id: consumer?.client_consumer_id,
        product_request_id: productRequest?.product_request_id,
        connection_id: yodleeConnection?.id,
        vendor_name: yodleeConnection?.vendor_name,
        event_details: data,
        vendor_institution_id: data.providerId,
      })
    );

    dispatch(
      handleWidgetSuccessCallbackAction({
        eventPayload: data,
        connectionId: yodleeConnection?.id,
        productRequestId: existingProductRequestId,
      })
    );
  };

  const onEventHandler = (event) => {
    dispatch(
      handleWidgetEventCallbackAction({
        eventPayload: event,
      })
    );
  };

  const onErrorHandler = (error) => {
    dispatch(
      logBusinessLoginFailed({
        consumer_id: consumer?.client_consumer_id,
        product_request_id: productRequest?.product_request_id,
        connection_id: yodleeConnection?.id,
        vendor_name: yodleeConnection?.vendor_name,
        event_details: error,
        vendor_institution_id: error.providerId,
      })
    );

    dispatch(
      handleWidgetErrorCallbackAction({
        eventPayload: error,
      })
    );
  };

  const onCloseHandler = (data) => {
    dispatch(
      handleWidgetCloseCallbackAction({
        eventPayload: data,
      })
    );
  };

  const initWidget = () => {
    const widgetInitParams = {
      productRequestId: existingProductRequestId,
      institutionId: institution?.institution_id,
    };

    dispatch(initializeYodleeWidgetAction(widgetInitParams));
  };

  useEffect(() => {
    try {
      if (shouldRender) {
        initWidget();
      }
    } catch (e) {
      props.onError(ApiErrorMessagesMapper(e, { isLastVendor: isLastVendor }));
      if (e.cause.statusCode === 401) {
        dispatch(toggleWidgetRender());
      }
      rollbar.error("Yodlee widget failed to init", { props: props, error: e });
    }
  }, []);

  const { ready, init } = useYodleeFastlink({
    containerId: "container-fastlink",
    config: yodleeWidgetConfiguration,
    onSuccess: onSuccessHandler,
    onEvent: onEventHandler,
    onClose: onCloseHandler,
    onError: onErrorHandler,
  });

  // Automatically show Yodlee widget on retry once ready
  useEffect(() => {
    if (ready && yodleeConfig.accessToken && yodleeConnection?.id) {
      dispatch(
        logBankSelectionEvent({
          eventPayload: {
            eventCode: "YODLEE_WIDGET_LOADED",
          },
        })
      );

      if (
        widgetConfiguration?.features?.enableSearchOnWrongAba &&
        !widgetConfiguration?.features?.bankSelection &&
        retryMode
      ) {
        dispatch(setRetryMode(false));
        showYodleeWidget();
      }

      if (
        (widgetConfiguration?.features?.ucwBankSelectionMode &&
          sequenceHistory.length > 1) ||
        retryMode
      ) {
        dispatch(setRetryMode(false));
        return showYodleeWidget();
      }
    }
  }, [ready, yodleeConfig.accessToken, yodleeConnection?.id]);

  const handleActiveBtnClick = (e, consumer, productRequest) => {
    e.preventDefault();

    dispatch(
      logBusinessClicked({
        consumer_id: consumer?.client_consumer_id,
        product_request_id: productRequest?.product_request_id,
        vendor_name: "yodlee",
        institution_id: institution?.institution_id,
      })
    );

    dispatch(
      logBankSelectionEvent({
        eventPayload: {
          eventCode: "YODLEE_CONNECT_BTN_CLICKED",
        },
      })
    );

    showYodleeWidget();
  };

  const activeBtn = () => {
    return (
      <ConnectMyAccountButton
        handleActiveBtnClick={(e) => {
          handleActiveBtnClick(e, consumer, productRequest);
        }}
      />
    );
  };

  const showYodleeWidget = () => {
    showFastlink();

    dispatch(
      updateYodleeConnectionAction({
        connectionId: yodleeConnection?.id,
        productRequestId: existingProductRequestId,
        status: "login_in_progress",
      })
    );

    init();
  };

  if (!widgetConfiguration?.features?.bankSelection) {
    return (
      <div className="ninja-fetch-widget__body flex justify-center">
        {ready && yodleeConfig.accessToken && !loading ? (
          activeBtn()
        ) : (
          <LoadingButton />
        )}
      </div>
    );
  }
};

export default connect(null)(YodleeWidget);
