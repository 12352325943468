import Rollbar from "rollbar";

export const rollbarService = (defaultEnvs = ['staging', 'production']) => {
  const targetEnv = process.env.TARGET_ENV

  if (defaultEnvs.indexOf(targetEnv) === -1) { return }

  const rollbarConfig = {
    accessToken: process.env.ROLLBAR_ACCESS_KEY,
    environment: targetEnv === 'production' ? 'production' : 'staging'
  };

  return {
    instance: new Rollbar(rollbarConfig),
    config: rollbarConfig
  }
}
