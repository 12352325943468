import {
  FLINKS_CONFIG_CANCEL_STATUS_POLLING,
  FLINKS_CONNECTION_UPDATE,
  FLINKS_HANDLE_FLINKS_WIDGET_CLOSE,
  FLINKS_HANDLE_FLINKS_WIDGET_ERROR,
  FLINKS_HANDLE_FLINKS_WIDGET_EVENT,
  FLINKS_HANDLE_FLINKS_WIDGET_SUCCESS,
  FLINKS_SET_FLINKS_WIDGET_CONFIGURATION,
  FLINKS_SET_VISIBLE,
  FLINKS_SHOULD_REUSE_CONNECTION,
  FLINKS_WIDGET_INIT,
  FLINKS_WIDGET_TOGGLE_LOADING,
  FLINKS_WIDGET_TOGGLE_SHOW,
} from "../constants/flinks-widget";
import { buildFlinksUrl } from "../shared/flinks-widget-utils";

export const setFlinksWidgetConfigurationAction = (payload) => {
  const params = {
    widgetUrl: buildFlinksUrl(
      payload.widget_configuration.url,
      payload.institutionId,
      payload.widget_configuration.params
    ),
  };
  return {
    type: FLINKS_SET_FLINKS_WIDGET_CONFIGURATION,
    payload: params,
  };
};

export const setFlinksWidgetVisible = () => {
  return {
    type: FLINKS_SET_VISIBLE,
  };
};

export const handleWidgetErrorCallbackAction = ({ eventPayload }) => {
  return {
    type: FLINKS_HANDLE_FLINKS_WIDGET_ERROR,
    payload: { eventPayload },
  };
};

export const handleWidgetSuccessCallbackAction = ({
  vendorResponse,
  connectionId,
  productRequestId,
}) => {
  return {
    type: FLINKS_HANDLE_FLINKS_WIDGET_SUCCESS,
    payload: { vendorResponse, connectionId, productRequestId },
  };
};

export const handleWidgetCloseCallbackAction = ({ eventPayload }) => {
  return {
    type: FLINKS_HANDLE_FLINKS_WIDGET_CLOSE,
    payload: { eventPayload },
  };
};

export const handleWidgetEventCallbackAction = ({ eventPayload }) => {
  return {
    type: FLINKS_HANDLE_FLINKS_WIDGET_EVENT,
    payload: { eventPayload },
  };
};

export const setShouldReuseConnection = () => {
  return {
    type: FLINKS_SHOULD_REUSE_CONNECTION,
  };
};

export const cancelFlinksWidgetConfigPolling = () => {
  return {
    type: FLINKS_CONFIG_CANCEL_STATUS_POLLING,
  };
};

export const updateFlinksConnectionAction = ({
  connectionId,
  vendorResponse,
  productRequestId,
  flinksProviderId,
  status,
}) => {
  return {
    type: FLINKS_CONNECTION_UPDATE,
    payload: {
      connectionId,
      vendorResponse,
      productRequestId,
      flinksProviderId,
      status,
    },
  };
};

export const toggleFlinksWidgetShow = () => {
  return {
    type: FLINKS_WIDGET_TOGGLE_SHOW,
  };
};

export const toggleFlinksWidgetLoading = (payload) => {
  return {
    type: FLINKS_WIDGET_TOGGLE_LOADING,
    payload: { loading: payload },
  };
};

export const initializeFlinksWidgetAction = ({
  productRequestId,
  institution,
}) => {
  return {
    type: FLINKS_WIDGET_INIT,
    payload: { productRequestId, institution },
  };
};
