import { useDispatch, useSelector } from "react-redux";
import { toggleBankSearchScreen } from "../../actions/bank-selection-actions";
import { setWidgetConfiguration } from "../../actions/widget-actions";
import {
  NEW_CONNECTION,
  NEW_CONNECTION_WITH_INSTITUTION_SELECTION,
} from "../../constants/masamune-widget";
import LoadingButton from "../shared/loading-button";
import { logBusinessClicked } from "../../actions/business-actions";

const ConnectMyAccount = () => {
  const flow = useSelector((state) => state.globalConfig.flow);
  const processing = useSelector((state) => state.globalConfig.processing);
  const isLoading = useSelector((state) => state.globalConfig.isLoading);
  const isAkoyaLoading = useSelector((state) => state.akoyaConfig.loading);
  const sophtronLoading = useSelector((state) => state.sophtronConfig.loading);
  const yodleeLoading = useSelector((state) => state.yodleeConfig.loading);
  const flinksLoading = useSelector((state) => state.flinksConfig.loading);

  const newConnectionFlows = [
    NEW_CONNECTION,
    NEW_CONNECTION_WITH_INSTITUTION_SELECTION,
  ];

  const dispatch = useDispatch();

  const handleActiveBtnClick = (e) => {
    e.preventDefault();

    dispatch(logBusinessClicked())

    if (newConnectionFlows.indexOf(flow) == -1) {
      dispatch(toggleBankSearchScreen(true));
      dispatch(
        setWidgetConfiguration({
          showModal: true,
          showNewConnectionWidgets: true,
        })
      );
    } else {
      dispatch(
        setWidgetConfiguration({
          showNewConnectionWidgets: true,
        })
      );
    }
  };

  if (
    isLoading ||
    processing ||
    isAkoyaLoading ||
    sophtronLoading ||
    yodleeLoading ||
    flinksLoading
  ) {
    return (
      <div className="ninja-fetch-widget__body flex justify-center">
        <LoadingButton />
      </div>
    );
  }

  return (
    <div className="ninja-fetch-widget__body flex justify-center">
      <button
        onClick={(e) => handleActiveBtnClick(e)}
        className="py-4 px-8 min-w-[250px] max-w-[350px] lg:py-2 lg:px-4 rounded-full text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 items-baseline"
      >
        Connect my Account
      </button>
    </div>
  );
};

export default ConnectMyAccount;
