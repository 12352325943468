import {
  SOPHTRON_CONFIG_CANCEL_STATUS_POLLING,
  SOPHTRON_CONNECTION_UPDATE,
  SOPHTRON_HANDLE_SOPHTRON_WIDGET_CLOSE,
  SOPHTRON_HANDLE_SOPHTRON_WIDGET_ERROR,
  SOPHTRON_HANDLE_SOPHTRON_WIDGET_EVENT,
  SOPHTRON_HANDLE_SOPHTRON_WIDGET_SUCCESS,
  SOPHTRON_SET_SOPHTRON_WIDGET_CONFIGURATION,
  SOPHTRON_SHOULD_REUSE_CONNECTION,
  SOPHTRON_WIDGET_INIT,
  SOPHTRON_WIDGET_LOADING,
} from "../constants/sophtron-widget";

export const setIsLoading = (isLoading) => {
  return {
    type: SOPHTRON_WIDGET_LOADING,
    payload: { loading: isLoading },
  };
};

export const setSophtronWidgetConfigurationAction = (payload) => {
  const params = {
    action: payload.widget_configuration.action,
    env: payload.widget_configuration.env,
    integrationKey: payload.widget_configuration.integration_key,
    integrationKeyExpiresAt:
      payload.widget_configuration.integration_key_expires_at,
    requestId: payload.widget_configuration.request_id,
  };

  return {
    type: SOPHTRON_SET_SOPHTRON_WIDGET_CONFIGURATION,
    payload: params,
  };
};

export const handleWidgetErrorCallbackAction = ({ eventPayload }) => {
  return {
    type: SOPHTRON_HANDLE_SOPHTRON_WIDGET_ERROR,
    payload: { eventPayload },
  };
};

export const handleWidgetSuccessCallbackAction = ({
  vendorResponse,
  connectionId,
  productRequestId,
}) => {
  return {
    type: SOPHTRON_HANDLE_SOPHTRON_WIDGET_SUCCESS,
    payload: { vendorResponse, connectionId, productRequestId },
  };
};

export const handleWidgetCloseCallbackAction = ({ eventPayload }) => {
  return {
    type: SOPHTRON_HANDLE_SOPHTRON_WIDGET_CLOSE,
    payload: { eventPayload },
  };
};

export const handleWidgetEventCallbackAction = ({ eventPayload }) => {
  return {
    type: SOPHTRON_HANDLE_SOPHTRON_WIDGET_EVENT,
    payload: { eventPayload },
  };
};

export const cancelSophtronWidgetConfigPolling = () => {
  return {
    type: SOPHTRON_CONFIG_CANCEL_STATUS_POLLING,
  };
};

export const setShouldReuseConnection = () => {
  return {
    type: SOPHTRON_SHOULD_REUSE_CONNECTION,
  };
};

export const updateSophtronConnectionAction = ({
  connectionId,
  vendorResponse,
  productRequestId,
  sophtronProviderId,
  status,
}) => {
  return {
    type: SOPHTRON_CONNECTION_UPDATE,
    payload: {
      connectionId,
      vendorResponse,
      productRequestId,
      sophtronProviderId,
      status,
    },
  };
};

export const initializeSophtronWidgetAction = ({
  activeConnections,
  productRequestId,
  institutionId,
}) => {
  return {
    type: SOPHTRON_WIDGET_INIT,
    payload: { activeConnections, productRequestId, institutionId },
  };
};
