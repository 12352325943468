import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setWidgetConfiguration } from "../../actions/widget-actions";
import {
  NEW_CONNECTION,
  NEW_CONNECTION_WITH_INSTITUTION_SELECTION,
} from "../../constants/masamune-widget";
import BlankWidgetScreen from "../shared/blank-widget-screen";
import AkoyaWidget from "../vendor-widgets/akoya-widget";
import FlinksWidget from "../vendor-widgets/flinks-widget";
import SophtronWidget from "../vendor-widgets/sophtron-widget";
import YodleeWidget from "../vendor-widgets/yodlee-widget";
import LoadingWidgetScreen from "./loading-widget-screen";

const newConnectionFlows = [
  NEW_CONNECTION,
  NEW_CONNECTION_WITH_INSTITUTION_SELECTION,
];

const VendorConnectionScreen = (props) => {
  const dispatch = useDispatch();
  const flow = useSelector((state) => state.globalConfig.flow);
  const productRequest = useSelector((state) => state.globalConfig.productRequest) || props.productRequest;
  const showNewConnectionWidgets = useSelector((state) => state.globalConfig.showNewConnectionWidgets);

  const vendorName = props.vendorName;
  let institution = props.institution;

  if (flow === NEW_CONNECTION_WITH_INSTITUTION_SELECTION) {
    institution = null;
  }

  useEffect(() => {
    if (newConnectionFlows.indexOf(flow) != -1) {
      dispatch(setWidgetConfiguration({ showModal: false }));
    }
  }, [flow, dispatch]);

  if (newConnectionFlows.indexOf(flow) == -1) {
    return <BlankWidgetScreen />;
  }

  if (!showNewConnectionWidgets) {
    return <BlankWidgetScreen />;
  }

  switch (vendorName) {
    case "yodlee":
      return (
        <YodleeWidget
          {...props}
          institution={institution}
          productRequestId={productRequest.product_request_id}
        />
      );
    case "sophtron":
      return (
        <SophtronWidget
          {...props}
          institution={institution}
          productRequestId={productRequest.product_request_id}
        />
      );
    case "flinks":
      return (
        <FlinksWidget
          {...props}
          institution={institution}
          productRequestId={productRequest.product_request_id}
        />
      );
    case "akoya":
      return (
        <AkoyaWidget
          {...props}
          institution={institution}
          productRequestId={productRequest.product_request_id}
        />
      );
    default:
      return <LoadingWidgetScreen />;
  }
};

export default VendorConnectionScreen;
