import {
  CLEAR_SEARCH_RESULTS,
  SET_FOUND_INSTITUTIONS,
  SET_NOT_SUPPORTED_INSTITUTION,
  SET_QUERY,
  SET_TOP_INSTITUTIONS,
  TOGGLE_SEARCH_SCREEN,
  TOGGLE_SEARCH_SCREEN_LOADING,
} from "../constants/bank-selection";

const initialState = {
  showSearchScreen: false,
  searchLoading: false,
  foundInstitutions: [],
  topInstitutions: [],
  query: "",
  unsupportedMappings: {},
};

export const BankSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FOUND_INSTITUTIONS:
      return { ...state, foundInstitutions: action.payload };
    case SET_TOP_INSTITUTIONS:
      return { ...state, topInstitutions: action.payload };
    case TOGGLE_SEARCH_SCREEN:
      return { ...state, showSearchScreen: action.payload };
    case CLEAR_SEARCH_RESULTS:
      return { ...state, ...action.payload };
    case TOGGLE_SEARCH_SCREEN_LOADING:
      return { ...state, searchLoading: !state.searchLoading };
    case SET_QUERY:
      return { ...state, query: action.payload };
    case SET_NOT_SUPPORTED_INSTITUTION:
      return { ...state, unsupportedMappings: action.payload };
    default:
      return state;
  }
};
