export const SET_FOUND_INSTITUTIONS = "BANK_SELECTION/SET_FOUND_INSTITUTIONS";
export const SET_INSTITUTION = "BANK_SELECTION/SET_INSTITUTION";
export const TOGGLE_SEARCH_SCREEN = "BANK_SELECTION/TOGGLE_SEARCH_SCREEN";
export const TOGGLE_SEARCH_SCREEN_LOADING =
  "BANK_SELECTION/TOGGLE_SEARCH_SCREEN_LOADING";
export const SET_QUERY = "BANK_SELECTION/SET_QUERY";
export const SET_TOP_INSTITUTIONS = "BANK_SELECTION/SET_TOP_INSTITUTIONS";
export const LOG_BANK_SEARCH_EVENT = "BANK_SELECTION/LOG_BANK_SEARCH_EVENT";
export const SET_NOT_SUPPORTED_INSTITUTION =
  "BANK_SELECTION/SET_NOT_SUPPORTED_INSTITUTION";
export const CLEAR_SEARCH_RESULTS = "BANK_SELECTION/CLEAR_SEARCH_RESULTS";
