import {
  call,
  delay,
  getContext,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  cancelFlinksWidgetConfigPolling,
  setFlinksWidgetConfigurationAction,
  toggleFlinksWidgetLoading,
} from "../actions/flinks-widget-actions";
import {
  closeModalsAndWidgets,
  logWidgetEvent,
  runProductRequestPolling,
  setCriticalIssueOccurred,
  setCurrentWidgetFlow,
  setDataPullProcessingTime,
  setFlinksConnection,
  setSwitchToNextVendor,
  setWidgetConfiguration,
  setWidgetFlow,
  stopProductRequestPolling,
  toggleProductRequestProcessing,
  toggleWidgetProcessing,
  toggleWidgetRender,
} from "../actions/widget-actions";
import {
  FLINKS_CONFIG_CANCEL_STATUS_POLLING,
  FLINKS_CONNECTION_UPDATE,
  FLINKS_HANDLE_FLINKS_WIDGET_CLOSE,
  FLINKS_HANDLE_FLINKS_WIDGET_ERROR,
  FLINKS_HANDLE_FLINKS_WIDGET_EVENT,
  FLINKS_HANDLE_FLINKS_WIDGET_SUCCESS,
  FLINKS_UNPROCESSABLE_ERROR_CODES,
  FLINKS_WIDGET_CONFIG_UPDATE,
  FLINKS_WIDGET_INIT,
  FLINKS_WIDGET_UPDATE_DELAY,
} from "../constants/flinks-widget";
import { ApiErrorMessagesMapper } from "../services/api-client";
import {
  findOrCreateFlinksConnection,
  updateFlinksConnectionRequest,
} from "../shared/flinks-widget-utils";
import {
  getWidgetConfiguration,
  isProductRequestFrozen
} from "../shared/masamune-widget-utils";
import { CONNECTION_FREEZE } from "../constants/masamune-widget";

const vendor = "flinks";

// Checks for already running pull to prevent duplicate pulls
const withProductRequestFreezeMiddleware = (worker) =>
  function* (action) {
    const globalConfig = yield select((state) => state.globalConfig);
    let requestFreeze = yield call(isProductRequestFrozen, globalConfig.productRequest?.product_request_id, globalConfig);

    if (requestFreeze) {
      yield put(setCurrentWidgetFlow(CONNECTION_FREEZE));
      return yield put(toggleFlinksWidgetLoading(false));
    }

    // If connection processing is not true, proceed with the original worker
    yield call(worker, action);
  };

function* processFlinksWidgetCallbackErrorWorker(action) {
  const { eventPayload } = action.payload;
  const eventCode = eventPayload.step;

  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;
  const { onError } = globalConfig.clientCallbackFunctions;
  const rollbar = yield getContext("rollbar");

  const connectionUpdateParams = {
    connectionId: globalConfig.flinksConnection?.id,
    productRequestId: globalConfig.productRequest?.product_request_id,
    edgeClientId: globalConfig.edgeClientId,
    vendorInstitutionId: eventPayload?.providerId,
    status: "login_failed",
    accessToken: globalConfig.accessToken,
    vendorResponse: eventPayload,
  };

  try {
    yield put(logWidgetEvent({ eventPayload, accessToken, vendor }));

    // yield put(toggleWidgetProcessing(true));
    yield call(updateFlinksConnectionRequest, connectionUpdateParams);

    if (FLINKS_UNPROCESSABLE_ERROR_CODES.indexOf(eventCode) !== -1) {
      rollbar.error(
        "Failed to connect Flinks with unprocessable code",
        eventCode,
        {
          params: connectionUpdateParams,
        }
      );
      yield delay(2000);

      yield put(setSwitchToNextVendor());
      yield put(setCriticalIssueOccurred());
      yield call(
        onError,
        ApiErrorMessagesMapper(eventPayload, {
          isLastVendor: globalConfig.isLastVendor,
        })
      );
      yield put(toggleWidgetProcessing(false));
      yield put(setWidgetConfiguration({ showModal: true }));
    }
  } catch (e) {
    rollbar.error("Failed to process error callback for Flinks", e, {
      params: connectionUpdateParams,
    });
    yield call(
      onError,
      ApiErrorMessagesMapper(e, { isLastVendor: globalConfig.isLastVendor })
    );

    /// Unmount on authentication error
    if (e?.cause?.statusCode === 401) {
      return yield put(toggleWidgetRender());
    }
    /// Switch to next on bad requests and server errors
    if (
      e?.cause?.statusCode === 500 ||
      e?.cause?.statusCode === 422 ||
      e?.cause?.statusCode === 400
    ) {
      yield put(setSwitchToNextVendor());
      return yield put(setCriticalIssueOccurred());
    }
    /// Switch to the next vendor if the Sophtron code itself trows some errors
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
    yield put(setWidgetConfiguration({ showModal: true }));
  }
}

function* processFlinksWidgetCallbackSuccessWorker(action) {
  const { vendorResponse, connectionId, productRequestId } = action.payload;
  const {
    accessToken,
    clientCallbackFunctions: { onError, onSuccess },
    edgeClientId,
    isLastVendor,
    productRequest
  } = yield select(state => state.globalConfig);
  const rollbar = yield getContext("rollbar");

  yield put(
    logWidgetEvent({ eventPayload: vendorResponse, accessToken, vendor })
  );

  const connectionUpdateParams = {
    connectionId,
    productRequestId,
    edgeClientId,
    accessToken,
    vendorResponse,
    vendorInstitutionId: vendorResponse?.providerId,
    status: "login_success",
  };

  try {
    yield put(setDataPullProcessingTime());
    // Just for flinks show processing screen just before updating the connection
    yield put(toggleProductRequestProcessing(true));
    yield put(setWidgetFlow());
    yield call(updateFlinksConnectionRequest, connectionUpdateParams);
    // Start product request polling
    yield put(runProductRequestPolling({ productRequestId, accessToken }));
  } catch (e) {
    rollbar.error("Failed to process success callback for Flinks", e, {
      params: connectionUpdateParams,
    });
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
    yield put(stopProductRequestPolling());
    yield put(cancelFlinksWidgetConfigPolling());
    yield call(
      onError,
      ApiErrorMessagesMapper(e, { isLastVendor })
    );
  }
  productRequest.ibv_report_status = "initiated"; // Workaround to fix the issue with the product request status on initial callback
  yield put(toggleFlinksWidgetLoading(false));
  yield call(onSuccess, productRequest);
}

function* processFlinksWidgetCallbackEventWorker(action) {
  const { eventPayload } = action.payload;
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;

  yield put(logWidgetEvent({ eventPayload, accessToken, vendor }));
}

function* processFlinksWidgetCallbackCloseWorker(action) {
  const { eventPayload } = action.payload;

  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;

  yield put(logWidgetEvent({ eventPayload, accessToken, vendor }));

  if (globalConfig?.widgetConfiguration?.features?.bankSelection) {
    yield put(closeModalsAndWidgets());
    yield put(
      setWidgetConfiguration({
        retryWithBankSelection: true,
        showModal: true,
      })
    );
  } else {
    yield put(setSwitchToNextVendor());
    yield put(setWidgetConfiguration({ showModal: true }));
  }
}

// Middleware-enhanced workers
const processFlinksWidgetCallbackCloseWorkerWithMiddleware = withProductRequestFreezeMiddleware(processFlinksWidgetCallbackCloseWorker);
const processFlinksWidgetCallbackSuccessWorkerWithMiddleware = withProductRequestFreezeMiddleware(processFlinksWidgetCallbackSuccessWorker);
const processFlinksWidgetCallbackEventWorkerWithMiddleware = withProductRequestFreezeMiddleware(processFlinksWidgetCallbackEventWorker);
const processFlinksWidgetCallbackErrorWorkerWithMiddleware = withProductRequestFreezeMiddleware(processFlinksWidgetCallbackErrorWorker);

// Watcher saga for Flinks widget callbacks
function* watchFlinksWidgetCallbacks() {
  yield takeEvery(
    FLINKS_HANDLE_FLINKS_WIDGET_CLOSE,
    processFlinksWidgetCallbackCloseWorkerWithMiddleware
  );
  yield takeEvery(
    FLINKS_HANDLE_FLINKS_WIDGET_SUCCESS,
    processFlinksWidgetCallbackSuccessWorkerWithMiddleware
  );
  yield takeEvery(
    FLINKS_HANDLE_FLINKS_WIDGET_EVENT,
    processFlinksWidgetCallbackEventWorkerWithMiddleware
  );
  yield takeEvery(
    FLINKS_HANDLE_FLINKS_WIDGET_ERROR,
    processFlinksWidgetCallbackErrorWorkerWithMiddleware
  );
}

function* handleFlinksConnectionUpdateWorker(action) {
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;
  const edgeClientId = globalConfig.edgeClientId;

  const connectionUpdateParams = {
    ...action.payload,
    accessToken,
    edgeClientId,
  };

  try {
    yield put(toggleFlinksWidgetLoading(true));

    const connection = yield call(
      updateFlinksConnectionRequest,
      connectionUpdateParams
    );

    yield put(setFlinksConnection(connection));
  } catch (e) {
    if (
      e.cause.statusCode === 401 ||
      e.cause.statusCode === 500 ||
      e.cause.statusCode === 422 ||
      e.cause.statusCode === 400
    ) {
      return yield put(toggleWidgetRender());
    }
  }
}

function* watchFlinksConnectionUpdate() {
  yield takeEvery(FLINKS_CONNECTION_UPDATE, handleFlinksConnectionUpdateWorker);
}

function* flinksConfigurationUpdateWorker(action) {
  const { connectionId } = action.payload;

  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;

  const widgetConfig = yield call(getWidgetConfiguration, {
    vendor,
    connectionId,
    accessToken,
  });

  yield put(setFlinksWidgetConfigurationAction(widgetConfig));
}

function* watchFlinksConfigurationUpdate() {
  yield takeLatest(
    FLINKS_WIDGET_CONFIG_UPDATE,
    flinksConfigurationUpdateWorker
  );
}

export function* updateFlinksConfigurationWorker() {
  // race effect takes the first who completed in parallel

  yield race({
    // Start the polling
    task: call(pollFlinksWidgetConfigurationWorker),
    // Start a take effect waiting for the cancel action.
    cancel: take(FLINKS_CONFIG_CANCEL_STATUS_POLLING),
  });
}

function* pollFlinksWidgetConfigurationWorker() {
  while (true) {
    const globalConfig = yield select((state) => state.globalConfig);
    const currentProvider = globalConfig.currentProvider;
    const { onError } = globalConfig.clientCallbackFunctions;
    const rollbar = yield getContext("rollbar");

    try {
      yield delay(FLINKS_WIDGET_UPDATE_DELAY);

      const configParams = {
        vendor,
        connectionId: globalConfig.flinksConnection?.id,
        accessToken: globalConfig.accessToken,
      };

      if (currentProvider !== vendor) {
        yield put(cancelFlinksWidgetConfigPolling()); // Cancel polling if vendor has changed
      }
      const widgetConfig = yield call(getWidgetConfiguration, configParams);

      yield put(setFlinksWidgetConfigurationAction(widgetConfig));
    } catch (e) {
      rollbar.error("Failure during flinks config poll", e);
      yield call(
        onError,
        ApiErrorMessagesMapper(e, { isLastVendor: globalConfig.isLastVendor })
      );
      yield put(toggleWidgetRender());
      yield put(cancelFlinksWidgetConfigPolling());
    }
  }
}

function* handleFlinksWidgetInitWorker(action) {
  const { productRequestId, institution } = action.payload;
  const globalConfig = yield select((state) => state.globalConfig);
  const { onError } = globalConfig.clientCallbackFunctions;
  const rollbar = yield getContext("rollbar");
  const accessToken = globalConfig.accessToken;
  const edgeClientId = globalConfig.edgeClientId;

  try {
    const connection = yield call(findOrCreateFlinksConnection, {
      productRequestId,
      edgeClientId,
      accessToken,
      institutionId: institution?.institution_id,
    });
    yield put(setFlinksConnection(connection));
    const widgetConfig = yield call(getWidgetConfiguration, {
      vendor,
      connectionId: connection.id,
      accessToken,
    });
    yield put(
      setFlinksWidgetConfigurationAction({
        ...widgetConfig,
        institutionId: institution?.vendor_institution_id,
      })
    );
  } catch (e) {
    rollbar.error("Failed to init Flinks widget", e, {
      params: action.payload,
    });

    yield call(
      onError,
      ApiErrorMessagesMapper(e, { isLastVendor: globalConfig.isLastVendor })
    );

    /// Unmount on authentication error
    if (e?.cause?.statusCode === 401) {
      return yield put(toggleWidgetRender());
    }
    /// Switch to next on bad requests and server errors
    if (
      e?.cause?.statusCode === 500 ||
      e?.cause?.statusCode === 422 ||
      e?.cause?.statusCode === 400
    ) {
      yield put(setSwitchToNextVendor());
      return yield put(setCriticalIssueOccurred());
    }
    /// Switch to the next vendor if the Sophtron code itself trows some errors
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
  }
}

function* watchFlinksWidgetInit() {
  yield takeEvery(FLINKS_WIDGET_INIT, handleFlinksWidgetInitWorker);
}

export default function* flinksWidgetSagas() {
  yield spawn(watchFlinksWidgetCallbacks);
  yield spawn(watchFlinksConnectionUpdate);
  yield spawn(watchFlinksConfigurationUpdate);
  yield spawn(watchFlinksWidgetInit);
}
