export const SOPHTRON_UNPROCESSABLE_ERROR_CODES = [
  // Uncomment these lines to test the final screen
  // 'HomeMenu_Timeout',
  // 'LogInPanel_Timeout',
  "AccountGreeting_Timeout",
  "AccountList_NoAccounts",
  "AccountLocked_Timeout",
  "Error_Timeout",
];

export const SOPHTRON_WIDGET_UPDATE_DELAY = 60000;

//ACTIONS
export const SOPHTRON_WIDGET_INIT = "SOPHTRON/WIDGET_INIT";
export const SOPHTRON_HANDLE_SOPHTRON_WIDGET_EVENT =
  "SOPHTRON/HANDLE_SOPHTRON_WIDGET_EVENT";
export const SOPHTRON_HANDLE_SOPHTRON_WIDGET_SUCCESS =
  "SOPHTRON/HANDLE_SOPHTRON_WIDGET_SUCCESS";
export const SOPHTRON_HANDLE_SOPHTRON_WIDGET_CLOSE =
  "SOPHTRON/HANDLE_SOPHTRON_WIDGET_CLOSE";
export const SOPHTRON_HANDLE_SOPHTRON_WIDGET_ERROR =
  "SOPHTRON/HANDLE_SOPHTRON_WIDGET_ERROR";
export const SOPHTRON_CONNECTION_UPDATE = "SOPHTRON/CONNECTION_UPDATE";
export const SOPHTRON_WIDGET_CONFIG_UPDATE = "SOPHTRON/WIDGET_CONFIG_UPDATE";
export const SOPHTRON_GET_WIDGET_CONFIGURATION =
  "SOPHTRON/GET_WIDGET_CONFIGURATION";
export const SOPHTRON_INIT_WIDGET = "SOPHTRON/INIT_WIDGET";
export const SOPHTRON_SET_SOPHTRON_WIDGET_CONFIGURATION =
  "SOPHTRON/SET_SOPHTRON_WIDGET_CONFIGURATION";
export const SOPHTRON_CONFIG_CANCEL_STATUS_POLLING =
  "SOPHTRON/CONFIG_CANCEL_STATUS_POLLING";
export const SOPHTRON_SHOULD_REUSE_CONNECTION =
  "SOPHTRON/SHOULD_REUSE_CONNECTION";
export const SOPHTRON_WIDGET_LOADING = "SOPHTRON/WIDGET_LOADING";
