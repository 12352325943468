import ApiClient from "../services/api-client";

export const resolveVendorWidgetConfig = (
  localConfig,
  intentUrl,
  connection,
  institution,
  skipProviderAccountId
) => {
  return {
    ...localConfig.vendorWidgetConfig,
    intent_url: intentUrl,
    provider_id: institution?.vendor_institution_id,
    provider_account_id: skipProviderAccountId
      ? null
      : connection?.vendor_connection_id,
  };
};

export const findOrCreateYodleeConnection = async ({
  productRequestId,
  institutionId,
  edgeClientId,
  accessToken,
}) => {
  const params = {
    edge_client_id: edgeClientId,
    product_request_id: productRequestId,
    institution_id: institutionId,
    vendor: "yodlee",
  };
  return await ApiClient.findOrCreateIbvConnection(params, accessToken);
};

export const updateYodleeConnectionRequest = async ({
  connectionId,
  vendorResponse,
  productRequestId,
  vendorInstitutionId,
  edgeClientId,
  accessToken,
  status,
}) => {
  const params = {
    vendor_response: vendorResponse,
    edge_client_id: edgeClientId,
    product_request_id: productRequestId,
    vendor_institution_id: vendorInstitutionId,
    status: status,
    vendor: "yodlee",
  };

  return await ApiClient.updateIbvConnection(connectionId, params, accessToken);
};

export const hideFastlink = () => {
  let fastlinkContainer = document.getElementById("container-fastlink");
  if (!fastlinkContainer) {
    return;
  }

  fastlinkContainer.innerHTML = "";
  fastlinkContainer.style.display = "none";
  return window.fastlink.close();
};

export const showFastlink = () => {
  const fastlinkContainer = document.getElementById("container-fastlink");
  fastlinkContainer.style.display = "flex";
};
