import {
  SOPHTRON_GET_WIDGET_CONFIGURATION,
  SOPHTRON_INIT_WIDGET,
  SOPHTRON_SET_SOPHTRON_WIDGET_CONFIGURATION,
  SOPHTRON_SHOULD_REUSE_CONNECTION,
  SOPHTRON_WIDGET_CONFIG_UPDATE,
  SOPHTRON_WIDGET_LOADING,
} from "../constants/sophtron-widget";

const initialState = {
  integrationKey: null,
  routingNumber: null,
  vendorInstitutionId: null,
  requestId: null,
  userInstitutionId: null,
  action: "Add",
  env: "preview",
  shouldReuseExistingConnection: false,
  integrationKeyExpiresAt: null,
  loading: false,
};

export const SophtronWidgetConfigurationReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SOPHTRON_WIDGET_LOADING:
      return { ...state, ...action.payload };
    case SOPHTRON_GET_WIDGET_CONFIGURATION:
      return { ...state, ...action.payload };
    case SOPHTRON_INIT_WIDGET:
      return { ...state, ...action.payload };
    case SOPHTRON_WIDGET_CONFIG_UPDATE:
      return { ...state, ...action.payload };
    case SOPHTRON_SET_SOPHTRON_WIDGET_CONFIGURATION:
      return { ...state, ...action.payload };
    case SOPHTRON_SHOULD_REUSE_CONNECTION:
      return { ...state, shouldReuseExistingConnection: true };
    default:
      return state;
  }
};
