import LoadingButton from "../shared/loading-button";

const LoadingWidgetScreen = () => {
  return (
    <div className="ninja-fetch-widget__body flex justify-center">
      <LoadingButton />
    </div>
  );
};

export default LoadingWidgetScreen;
