// Sourced from UCW source code
export const UCW_ERROR_STEPS = [
  "loginError",
  "error",
  "oauthError",
  "verifyError"
];

// Actions
export const UCW_CONFIG_CANCEL_STATUS_POLLING =
  "UCW/CONFIG_CANCEL_STATUS_POLLING";
export const UCW_HANDLE_WIDGET_EVENT = "UCW/HANDLE_WIDGET_EVENT";
export const UCW_HANDLE_WIDGET_SUCCESS = "UCW/HANDLE_WIDGET_SUCCESS";
export const UCW_HANDLE_WIDGET_CLOSE = "UCW/HANDLE_WIDGET_CLOSE";
export const UCW_HANDLE_WIDGET_ERROR = "UCW/HANDLE_WIDGET_ERROR";
export const UCW_CONNECTION_UPDATE = "UCW/CONNECTION_UPDATE";
export const UCW_SET_WIDGET_CONFIGURATION = "UCW/SET_WIDGET_CONFIGURATION";
export const UCW_WIDGET_INIT = "UCW/WIDGET_INIT";
export const UCW_WIDGET_CONFIG_UPDATE = "UCW/WIDGET_CONFIG_UPDATE";
export const UCW_WIDGET_LOADING = "UCW/WIDGET_LOADING";
export const UCW_SEARCH_MODE = "UCW/SEARCH_MODE";
