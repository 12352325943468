import { useDispatch } from "react-redux";
import { toggleWidgetRender } from "../../actions/widget-actions";

const SomethingWentWrongDialog = () => {
  const dispatch = useDispatch();

  const unmountTheWidget = () => {
    dispatch(toggleWidgetRender());
  };

  const handleApproveClick = (e) => {
    e.preventDefault();

    unmountTheWidget();
  };

  return (
    <div className="flex justify-center flex-col">
      <p className="text-center">
        Technical error, please retry with a different method
      </p>
      <div className="ninja-fetch-widget__body flex justify-center">
        <button
          onClick={(e) => handleApproveClick(e)}
          className="py-4 px-8 lg:py-2 min-w-[250px] max-w-[350px] lg:px-4 mt-5 rounded-full text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 items-baseline"
        >
          Ok
        </button>
      </div>
    </div>
  );
};

export default SomethingWentWrongDialog;
