import { useDispatch, useSelector } from "react-redux";
import {
  handleWidgetCloseCallbackAction,
  handleWidgetErrorCallbackAction,
  handleWidgetEventCallbackAction,
  handleWidgetSuccessCallbackAction,
  initializeUcwWidgetAction,
  setIsLoading,
  updateUcwConnectionAction,
} from "../../actions/ucw-widget-actions";
import { useEffect, useRef, useState } from "react";
import { MonitoringNotificationsService } from "../../services/monitoring-notification-service";
import { ApiErrorMessagesMapper } from "../../services/api-client";
import {
  addSequenceToHistory,
  changeMasamuneCurrentVendorIndex,
  logWidgetEvent,
  setCurrentWidgetFlow,
  setIbvSequence,
  toggleWidgetRender,
} from "../../actions/widget-actions";
import sophtron from "../../lib/universal-widget-loader";
import {
  logBusinessClicked,
  logBusinessLoginFailed,
  logBusinessLoginSucceeded,
} from "../../actions/business-actions";
import LoadingButton from "../shared/loading-button";
import { GrClose } from "react-icons/gr";
import { UCW_ERROR_STEPS } from "../../constants/ucw-widget";
import {
  getNormalizedSequence,
  sequencesAreEqual,
} from "../../shared/sequence-api-utils";
import { NO_VENDORS_IN_SEQUENCE } from "../../constants/masamune-widget";

const rollbar = MonitoringNotificationsService("rollbar");

const UcwWidget = ({
  institution,
  productRequestId,
  onError,
  consumer_data,
}) => {
  const dispatch = useDispatch();
  const [closeDisplayClass, setCloseDisplayClass] = useState("hidden");
  const consumerData = consumer_data;

  const {
    consumer,
    isLastVendor,
    productRequest,
    shouldRender,
    ucwConnection,
    retryMode,
    accessToken,
    ibvSequence,
    currVendorIdx,
    widgetConfiguration,
  } = useSelector((state) => state.globalConfig);

  const { authCode, env, loading, selectedInstitutionId, userId } = useSelector(
    (state) => state.ucwConfig
  );

  const selectedInstitutionIdRef = useRef(selectedInstitutionId);
  const selectedInstitutionNameRef = useRef(null);
  const connected = useRef(false);

  const logLoginFailed = (e) => {
    dispatch(
      logBusinessLoginFailed({
        consumer_id: consumer?.client_consumer_id,
        product_request_id: productRequest?.product_request_id,
        connection_id: ucwConnection?.id,
        vendor_name: ucwConnection?.vendor_name,
        event_details: e,
        vendor_institution_id: institution?.vendor_institution_id,
      })
    );
  };

  const initWidget = () => {
    const widgetInitParams = {
      productRequestId: productRequestId,
      institutionId: institution?.institution_id,
    };

    dispatch(initializeUcwWidgetAction(widgetInitParams));
  };

  useEffect(() => {
    try {
      if (shouldRender) initWidget();
    } catch (e) {
      rollbar.error("Failed to init ucw widget", e);
      onError(ApiErrorMessagesMapper(e, { isLastVendor: isLastVendor }));
      if (e.cause.statusCode === 401) dispatch(toggleWidgetRender());
    }
  }, []);

  useEffect(() => {
    if (authCode && ucwConnection && retryMode) showUcwWidget();
  }, [authCode, ucwConnection, retryMode]);

  const onInit = () => {
    dispatch(
      logWidgetEvent({
        eventPayload: { eventCode: "UCW_WIDGET_LOADED" },
        vendor: "ucw",
      })
    );
  };

  const onEvent = async (e) => {
    if (connected.current || e.event !== "vcs/connect/stepChange") return;

    const eventData = e.data;
    const step = eventData?.current;

    const errorStep = UCW_ERROR_STEPS.includes(step);
    const loginScreenStep = step === "enterCreds";

    const connectionUpdatePayload = {
      connectionId: ucwConnection.id,
      productRequestId,
      vendorResponse: e,
    };

    if (errorStep) {
      connectionUpdatePayload.status = "login_failed";
      logLoginFailed(e);
      dispatch(handleWidgetEventCallbackAction({ eventPayload: e }));
    }

    if (
      loginScreenStep &&
      widgetConfiguration?.features?.ucwBankSelectionMode
    ) {
      await handleBankSelection(consumerData, accessToken, e);
    }

    // To prevent race condition with onfinish handler
    if (step === "connected") return;

    if (!loginScreenStep) {
      dispatch(updateUcwConnectionAction(connectionUpdatePayload));
    }
  };
  const onSelectBank = async (e) => {
    selectedInstitutionIdRef.current = e?.data?.code;
    selectedInstitutionNameRef.current = e?.data?.name;
  };

  const handleBankSelection = async (consumer_data, access_token, e) => {
    const eventData = e.data;
    sophtron.hide();
    setCloseDisplayClass("hidden");
    const routingNumber = consumerData?.routing_number;
    const vendorInstitutionId = eventData?.current_institution;

    dispatch(setIsLoading(true));

    const normalizedIbvSequenceResponse = await getNormalizedSequence({
      consumer,
      vendorInstitutionId,
      routingNumber,
      accessToken,
    });

    dispatch(addSequenceToHistory(normalizedIbvSequenceResponse));

    if (normalizedIbvSequenceResponse.length === 0) {
      return;
    }

    if (normalizedIbvSequenceResponse.ibvSequence.vendors.length === 0) {
      rollbar.error(
        `Failed to find mapping for institution id ${vendorInstitutionId}`,
        e.data
      );
      dispatch(setCurrentWidgetFlow(NO_VENDORS_IN_SEQUENCE));
      return;
    }

    const seqNotChanged = sequencesAreEqual(
      normalizedIbvSequenceResponse.ibvSequence,
      ibvSequence
    );

    if (
      seqNotChanged ||
      normalizedIbvSequenceResponse.ibvSequence.vendors[0].vendor_name === "ucw"
    ) {
      sophtron.show();
      return;
    }

    // Disable UCW connection
    dispatch(
      updateUcwConnectionAction({
        connectionId: ucwConnection?.id,
        productRequestId: productRequestId,
        vendorResponse: e,
        status: "replaced",
      })
    );

    dispatch(setIbvSequence(normalizedIbvSequenceResponse));
    dispatch(changeMasamuneCurrentVendorIndex(currVendorIdx));
    dispatch(setIsLoading(false));
  };

  const onClose = (e) => {
    dispatch(handleWidgetCloseCallbackAction({ eventPayload: e }));
    dispatch(setIsLoading(false));
    setCloseDisplayClass("hidden");
    sophtron.hide();
  };

  const onFinish = (e) => {
    if (connected.current) return;

    const vendorInstitutionId = selectedInstitutionIdRef.current;
    const vendorName = selectedInstitutionNameRef.current;

    dispatch(
      logBusinessLoginSucceeded({
        consumer_id: userId,
        product_request_id: productRequest?.product_request_id,
        connection_id: ucwConnection?.id,
        vendor_name: ucwConnection?.vendor_name,
        event_details: e,
        vendor_institution_id: vendorInstitutionId,
      })
    );

    dispatch(
      handleWidgetSuccessCallbackAction({
        vendorResponse: { ...e, vendorInstitutionId, vendorName },
        connectionId: ucwConnection?.id,
        productRequestId: productRequestId,
      })
    );

    connected.current = true;
    sophtron.hide();
  };

  const ucwOnError = (e) => {
    logLoginFailed(e);
    dispatch(handleWidgetErrorCallbackAction({ eventPayload: e }));
    sophtron.hide();
  };

  const showUcwWidget = () => {
    dispatch(
      updateUcwConnectionAction({
        connectionId: ucwConnection?.id,
        productRequestId: productRequestId,
        status: "login_in_progress",
      })
    );

    sophtron.init(
      {
        auth: authCode,
        user_id: userId,
        institution_id: institution?.vendor_institution_id,
        env,
        jobType: "all",
        params: {},
        onEvent: onEvent,
        onShow: onEvent,
        onInit,
        onClose,
        onSelectBank,
        onLogin: onEvent,
        onLoginSuccess: onEvent,
        onMfa: onEvent,
        onFinish,
        onError: ucwOnError,
      },
      true
    );

    setCloseDisplayClass("");
  };

  const handleActiveBtnClick = (e, consumer, productRequest) => {
    e.preventDefault();

    dispatch(
      logBusinessClicked({
        consumer_id: consumer?.client_consumer_id,
        product_request_id: productRequest?.id,
        vendor_name: "ucw",
        institution_id: institution?.institution_id,
      })
    );

    dispatch(
      logWidgetEvent({
        eventPayload: {
          eventCode: "UCW_CONNECT_BTN_CLICKED",
        },
        vendor: "ucw",
      })
    );

    showUcwWidget();
  };

  return (
    <div className="ninja-fetch-widget__body flex justify-center">
      {authCode && ucwConnection && !loading ? (
        <button
          onClick={(e) => handleActiveBtnClick(e, consumer, productRequest)}
          className="py-4 px-8 lg:py-2 lg:px-4 rounded-full text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
        >
          Connect my Account
        </button>
      ) : (
        <LoadingButton />
      )}
      <GrClose
        className={`${closeDisplayClass} z-50 fixed cursor-pointer`}
        style={{ top: 30, marginLeft: "330px" }}
        onClick={() => onClose()}
      />
    </div>
  );
};

export default UcwWidget;
