import { useEffect, useState } from "react";

const useYodleeFastlink = ({
  containerId,
  config,
  onSuccess,
  onError,
  onClose,
  onEvent,
  createScriptTag = true,
}) => {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    let script;
    const existingScript = document.getElementById("yodlee-fastlink-script");

    // Check if FastLink is already present
    if (window.fastlink) {
      window.fastlink.close();
      console.warn("FastLink is already initialized.");
      return setReady(true);
    }

    if (createScriptTag && !existingScript) {
      script = document.createElement("script");
      script.id = "yodlee-fastlink-script";
      script.src = "https://cdn.yodlee.com/fastlink/v4/initialize.js";
      script.async = true;
      script.defer = true;
      script.onload = () => setReady(true);
      script.onerror = () =>
        setError("Yodlee FastLink library could not be loaded!");

      document.body.appendChild(script);
    }

    return () => {
      if (window.fastlink) {
        window.fastlink.close();
      }
      setActive(false);

      if (createScriptTag) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const close = () => {
    setActive(false);
    if (window.fastlink) {
      window.fastlink.close();
    }
    return true;
  };

  const init = () => {
    const {
      access_token,
      fastlink_url,
      config_name,
      environment,
      callback_url,
      intent_url,
      ob_app_name,
      provider_account_id,
      provider_id,
    } = config;

    const resolveConfig = () => {
      let baseConfig = {
        accessToken: access_token,
        fastLinkURL: fastlink_url,
        iframeScrolling: "yes",
        params: {
          configName: config_name,
          environment,
          callback: callback_url,
        },
      };

      if (intent_url) {
        baseConfig.params.intentUrl = intent_url;
        baseConfig.params.isIFrameMounted = true;
        baseConfig.forceIframe = true;
      }
      if (ob_app_name) {
        baseConfig.params.obAppName = ob_app_name;
      }
      if (provider_account_id) {
        baseConfig.params.providerAccountId = provider_account_id;
        baseConfig.params.flow = "refresh";
      }
      if (provider_id) {
        baseConfig.params.providerId = provider_id;
        baseConfig.params.flow = "add";
      }

      return baseConfig;
    };

    if (active || window.fastlink) {
      window.fastlink?.close();
    }

    if (typeof window.fastlink.open !== "function") {
      setError("Fastlink open method not found.");
      return;
    }

    window.fastlink.open(
      {
        ...resolveConfig(),
        forceIframe: true, // Ensure widget compatibility on mobile Safari
        onSuccess: (customerData) => {
          setData(customerData);
          onSuccess && onSuccess(customerData);
          close();
        },
        onError: (fastLinkError) => {
          setError(fastLinkError);
          onError && onError(fastLinkError);
        },
        onClose: (data) => {
          close();
          onClose && onClose(data);
        },
        onEvent: (event) => {
          onEvent && onEvent(event);
        },
      },
      containerId
    );

    setActive(true);
  };

  return {
    init,
    data,
    error,
    ready,
    active,
    close,
  };
};

export default useYodleeFastlink;
