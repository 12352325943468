import { connect, useSelector } from "react-redux";

import {
  BANK_SELECTION,
  CONNECTION_FREEZE,
  CRITICAL_ERROR,
  MULTIPLE_INSTITUTIONS_SCREEN,
  NEW_CONNECTION,
  NEW_CONNECTION_WITH_INSTITUTION_SELECTION,
  NO_VENDORS_IN_SEQUENCE,
  REQUEST_PROCESSING,
  REUSE_CONNECTION,
  SWITCH_VENDOR,
  WIDGET_LOADING,
} from "../../constants/masamune-widget";

import LoadingWidgetScreen from "./loading-widget-screen";
import ReuseConnectionDialog from "./reuse-connection-dialog";
import RetryDialog from "./retry-dialog";
import SomethingWentWrongDialog from "./something-went-wrong-dialog";
import SelectInstitutionDialog from "./select-institution-dialog";
import NewConnectionFlow from "./new-connection-flow";
import ProcessingMessage from "./processing-message";
import BankSearchScreen from "./bank-search-screen";
import NoVendorsInSequenceMessage from "./no-vendors-in-sequence-message";
import ConnectionFreezeMessage from "./connection-freeze-message";

const productRequestFromState = (state) => state.globalConfig.productRequest;

const MasamuneWidgetBody = (props) => {
  const flow = useSelector((state) => state.globalConfig.flow);
  const foundInstitutions = useSelector(
    (state) => state.globalConfig.foundInstitutions
  );
  const productRequest =
    useSelector(productRequestFromState) || props.productRequest;

  const { institution, vendorName } = props;
  const routingNumber = props?.consumer_data?.routing_number || productRequest?.routingNumber;
  switch (flow) {
    case WIDGET_LOADING:
      return <LoadingWidgetScreen />;
    case BANK_SELECTION:
      return <BankSearchScreen {...props} />;
    case CONNECTION_FREEZE:
      return <ConnectionFreezeMessage />;
    case SWITCH_VENDOR:
      return <RetryDialog routingNumber={routingNumber} />;
    case CRITICAL_ERROR:
      return <SomethingWentWrongDialog {...props} />;
    case REQUEST_PROCESSING:
      return <ProcessingMessage />;
    case NEW_CONNECTION_WITH_INSTITUTION_SELECTION:
      return (
        <NewConnectionFlow
          {...props}
          vendor={vendorName}
          institution={null}
          productRequestId={productRequest?.product_request_id}
          ignoreExistingConnection={true}
        />
      );
    case NEW_CONNECTION:
      return (
        <NewConnectionFlow
          {...props}
          vendor={vendorName}
          productRequestId={productRequest?.product_request_id}
          institution={institution}
        />
      );
    case REUSE_CONNECTION:
      return (
        <ReuseConnectionDialog
          {...props}
          institution={institution}
          vendor={vendorName}
          productRequestId={productRequest?.product_request_id}
          foundInstitutions={foundInstitutions}
        />
      );
    case MULTIPLE_INSTITUTIONS_SCREEN:
      return (
        <SelectInstitutionDialog
          {...props}
          institutions={foundInstitutions}
          vendor={vendorName}
        />
      );
    case NO_VENDORS_IN_SEQUENCE:
      return <NoVendorsInSequenceMessage />;
    default:
      return <LoadingWidgetScreen />;
  }
};

export default connect(null)(MasamuneWidgetBody);
