import {
  call,
  getContext,
  put,
  select,
  spawn,
  takeEvery,
} from "redux-saga/effects";
import {
  setAkoyaWidgetConfigurationAction,
  setError
} from "../actions/akoya-actions";
import {
  setAkoyaConnection,
  setCriticalIssueOccurred,
  setCurrentWidgetFlow,
  setSwitchToNextVendor,
  toggleWidgetRender,
} from "../actions/widget-actions";
import { AKOYA_CONNECTION_UPDATE, AKOYA_INIT } from "../constants/akoya-widget";
import { ApiErrorMessagesMapper } from "../services/api-client";
import {
  findOrCreateAkoyaConnection,
  updateAkoyaConnectionRequest,
} from "../shared/akoya-widget-utils";
import { getWidgetConfiguration, isProductRequestFrozen } from "../shared/masamune-widget-utils";
import { CONNECTION_FREEZE } from "../constants/masamune-widget";
import { setIsLoading } from "../actions/ucw-widget-actions";

function* handleAkoyaInitWorker(action) {
  const { productRequestId, institution } = action.payload;
  const globalConfig = yield select((state) => state.globalConfig);
  const rollbar = yield getContext("rollbar");

  const { onError } = globalConfig.clientCallbackFunctions;
  const accessToken = globalConfig.accessToken;
  const edgeClientId = globalConfig.edgeClientId;
  const vendor = "akoya";

  try {
    const connection = yield call(findOrCreateAkoyaConnection, {
      productRequestId,
      institutionId: institution?.institution_id,
      edgeClientId,
      accessToken,
    });
    yield put(setAkoyaConnection(connection));
    const widgetConfig = yield call(getWidgetConfiguration, {
      vendor,
      connectionId: connection.id,
      vendorInstitutionId: institution?.vendor_institution_id,
      productRequestId,
      accessToken,
    });
    yield put(setAkoyaWidgetConfigurationAction(widgetConfig));
  } catch (e) {
    yield put(setError(e))
    rollbar.error("Failed to init Akoya widget", e, {
      params: action.payload,
    });

    yield call(
      onError,
      ApiErrorMessagesMapper(e, { isLastVendor: globalConfig.isLastVendor })
    );

    /// Unmount on authentication error
    if (e?.cause?.statusCode === 401) {
      return yield put(toggleWidgetRender());
    }
    /// Switch to next on bad requests and server errors
    if (
      e?.cause?.statusCode === 500 ||
      e?.cause?.statusCode === 422 ||
      e?.cause?.statusCode === 400
    ) {
      yield put(setSwitchToNextVendor());
      return yield put(setCriticalIssueOccurred());
    }
    /// Switch to the next vendor if the Sophtron code itself trows some errors
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
  }
}

// Workers

function* handleYodleeConnectionUpdateWorker(action) {
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;
  const edgeClientId = globalConfig.edgeClientId;
  const connectionUpdateParams = {
    ...action.payload,
    accessToken,
    edgeClientId,
  };

  let requestFreeze = yield call(isProductRequestFrozen, globalConfig.productRequest?.product_request_id, globalConfig);
  if (requestFreeze) {
    yield put(setCurrentWidgetFlow(CONNECTION_FREEZE));
    return yield put(setIsLoading(false));
  }

  yield call(updateAkoyaConnectionRequest, connectionUpdateParams);
}

// Watchers

function* watchAkoyaInitEvent() {
  yield takeEvery(AKOYA_INIT, handleAkoyaInitWorker);
}

function* watchAkoyaConnectionUpdate() {
  yield takeEvery(AKOYA_CONNECTION_UPDATE, handleYodleeConnectionUpdateWorker);
}

export default function* akoyaSagas() {
  yield spawn(watchAkoyaConnectionUpdate);
  yield spawn(watchAkoyaInitEvent);
}
