import {
  AKOYA_CONNECTION_UPDATE,
  AKOYA_INIT,
  AKOYA_SET_WIDGET_CONFIGURATION,
  AKOYA_TOGGLE_LOADING,
  AKOYA_SET_ERROR,
} from "../constants/akoya-widget";

export const initializeAkoyaWidgetAction = ({
  productRequestId,
  institution,
}) => {
  return {
    type: AKOYA_INIT,
    payload: { productRequestId, institution },
  };
};

export const setAkoyaLoadingAction = (payload) => {
  return {
    type: AKOYA_TOGGLE_LOADING,
    payload: { loading: payload },
  };
};

export const setError = (payload) => {
  return {
    type: AKOYA_SET_ERROR,
    payload: { error: payload },
  };
};

export const updateAkoyaConnectionAction = ({
  connectionId,
  vendorResponse,
  productRequestId,
  akoyaProviderId,
  status,
}) => {
  return {
    type: AKOYA_CONNECTION_UPDATE,
    payload: {
      connectionId,
      vendorResponse,
      productRequestId,
      akoyaProviderId,
      status,
    },
  };
};

export const setAkoyaWidgetConfigurationAction = (payload) => {
  return {
    type: AKOYA_SET_WIDGET_CONFIGURATION,
    payload: payload.widget_configuration,
  };
};
