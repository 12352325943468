import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import LoadingButton from "../shared/loading-button";
import { logBankSelectionEvent } from "../../actions/bank-selection-actions";
import {
  handleWidgetCloseCallbackAction,
  handleWidgetErrorCallbackAction,
  handleWidgetEventCallbackAction,
  handleWidgetSuccessCallbackAction,
  initializeSophtronWidgetAction,
  setIsLoading,
  updateSophtronConnectionAction,
} from "../../actions/sophtron-widget-actions";
import { setRetryMode, toggleWidgetRender } from "../../actions/widget-actions";
import useSophtronWidget from "../../hooks/use-sophtron-widget";
import { ApiErrorMessagesMapper } from "../../services/api-client";
import {
  logBusinessClicked,
  logBusinessLoginFailed,
  logBusinessLoginSucceeded,
} from "../../actions/business-actions";
import { MonitoringNotificationsService } from "../../services/monitoring-notification-service";
import ConnectMyAccountButton from "../masamune-widget/connect-my-account-button";

const rollbar = MonitoringNotificationsService("rollbar");

const SophtronWidget = (props) => {
  const dispatch = useDispatch();
  const globalConfig = useSelector((state) => state.globalConfig);
  const sophtronConfig = useSelector((state) => state.sophtronConfig);
  const sequenceHistory = useSelector(
    (state) => state.globalConfig.sequenceHistory
  );
  const institution = props.institution;
  const existingProductRequestId = props.productRequestId;
  const widgetConfiguration = useSelector(
    (state) => state.globalConfig.widgetConfiguration
  );
  const retryMode = useSelector((state) => state.globalConfig.retryMode);
  const sophtronConnection = useSelector(
    (state) => state.globalConfig.sophtronConnection
  );
  const loading = useSelector((state) => state.sophtronConfig.loading);

  const consumer = useSelector((state) => state.globalConfig.consumer);
  const productRequest = useSelector(
    (state) => state.globalConfig.productRequest
  );

  const onSuccessHandler = (data) => {
    dispatch(
      logBusinessLoginSucceeded({
        consumer_id: consumer?.client_consumer_id,
        product_request_id: productRequest?.product_request_id,
        connection_id: sophtronConnection?.id,
        vendor_name: sophtronConnection?.vendor_name,
        event_details: data,
        vendor_institution_id: data?.institutionId,
      })
    );

    dispatch(
      handleWidgetSuccessCallbackAction({
        vendorResponse: data,
        connectionId: globalConfig.sophtronConnection?.id,
        productRequestId: existingProductRequestId,
      })
    );
  };

  const onEventHandler = (event) => {
    dispatch(
      handleWidgetEventCallbackAction({
        eventPayload: event,
      })
    );
  };

  const onErrorHandler = (error) => {
    dispatch(
      logBusinessLoginFailed({
        consumer_id: consumer?.client_consumer_id,
        product_request_id: productRequest?.product_request_id,
        connection_id: sophtronConnection?.id,
        vendor_name: sophtronConnection?.vendor_name,
        event_details: error,
        vendor_institution_id: institution?.vendor_institution_id,
      })
    );
    dispatch(
      handleWidgetErrorCallbackAction({
        eventPayload: error,
      })
    );
  };

  const onCloseHandler = (data) => {
    dispatch(handleWidgetCloseCallbackAction({ eventPayload: data }));
  };

  const initWidget = () => {
    const widgetInitParams = {
      productRequestId: existingProductRequestId,
      institutionId: institution?.institution_id,
    };

    dispatch(initializeSophtronWidgetAction(widgetInitParams));
  };

  const { ready, init } = useSophtronWidget({
    env: sophtronConfig?.env,
    integrationKey: sophtronConfig?.integrationKey,
    requestId: sophtronConfig?.requestId,
    institutionId: institution?.vendor_institution_id,
    onClose: onCloseHandler,
    onFinish: onSuccessHandler,
    onError: onErrorHandler,
    onEvent: onEventHandler,
    onLogin: onEventHandler,
    onSelectBank: onEventHandler,
    onMfa: onEventHandler,
  });

  useEffect(() => {
    try {
      if (globalConfig.shouldRender) {
        initWidget();
      }
    } catch (e) {
      rollbar.error("Failed to init Sophtron widget", e);

      props.onError(
        ApiErrorMessagesMapper(e, { isLastVendor: globalConfig.isLastVendor })
      );

      if (e.cause.statusCode === 401) {
        dispatch(toggleWidgetRender());
      }
    }
  }, []);

  useEffect(() => {
    if (ready && sophtronConfig?.integrationKey && sophtronConnection?.id) {
      dispatch(
        logBankSelectionEvent({
          eventPayload: {
            eventCode: "SOPHTRON_WIDGET_LOADED",
          },
        })
      );
      if (
        widgetConfiguration?.features?.enableSearchOnWrongAba &&
        !widgetConfiguration?.features?.bankSelection &&
        retryMode
      ) {
        dispatch(setRetryMode(false));
        showSophtronWidget();
      }

      if (
        (widgetConfiguration?.features?.ucwBankSelectionMode &&
          sequenceHistory.length > 1) ||
        retryMode
      ) {
        dispatch(setRetryMode(false));
        showSophtronWidget();
        dispatch(setIsLoading(false));
      }
    }
  }, [ready, sophtronConfig?.integrationKey, sophtronConnection?.id]);

  const handleActiveBtnClick = (e, consumer, productRequest) => {
    e.preventDefault();

    dispatch(
      logBusinessClicked({
        consumer_id: consumer?.client_consumer_id,
        product_request_id: productRequest?.id,
        vendor_name: "sophtron",
        institution_id: institution?.institution_id,
      })
    );

    dispatch(
      logBankSelectionEvent({
        eventPayload: {
          eventCode: "SOPHTRON_CONNECT_BTN_CLICKED",
        },
      })
    );

    showSophtronWidget();
  };

  const activeBtn = () => {
    return (
      <ConnectMyAccountButton
        handleActiveBtnClick={(e) => {
          handleActiveBtnClick(e, consumer, productRequest);
        }}
      />
    );
  };

  const showSophtronWidget = () => {
    dispatch(
      updateSophtronConnectionAction({
        connectionId: globalConfig.sophtronConnection?.id,
        productRequestId: existingProductRequestId,
        status: "login_in_progress",
      })
    );

    init();
  };

  if (!widgetConfiguration?.features?.bankSelection) {
    return (
      <div className="ninja-fetch-widget__body flex justify-center">
        {ready && sophtronConfig?.integrationKey && !loading ? (
          activeBtn()
        ) : (
          <LoadingButton />
        )}
      </div>
    );
  }
};

export default connect(null)(SophtronWidget);
