import { useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  initializeAkoyaWidgetAction,
  setAkoyaLoadingAction,
  updateAkoyaConnectionAction,
} from "../../actions/akoya-actions";
import { logBankSelectionEvent } from "../../actions/bank-selection-actions";
import {
  runProductRequestPolling,
  setConnectionProcessingTime,
  setDataPullProcessingTime,
  startConnectionPolling,
  setSwitchToNextVendor,
  toggleProductRequestProcessing,
  toggleWidgetRender,
} from "../../actions/widget-actions";
import { ApiErrorMessagesMapper } from "../../services/api-client";
import { sendMessageToNative } from "../../shared/masamune-widget-utils";
import LoadingButton from "../shared/loading-button";
import {
  logBusinessClicked,
  logBusinessLoginSucceeded,
} from "../../actions/business-actions";
import { MonitoringNotificationsService } from "../../services/monitoring-notification-service";
import ConnectMyAccountButton from "../masamune-widget/connect-my-account-button";
import { AKOYA_CONNECTION_SUCCESS_STATUSES } from "../../constants/akoya-widget";

const rollbar = MonitoringNotificationsService("rollbar");

export const AkoyaWidget = ({
  institution,
  onError,
  onSuccess,
  productRequestId
}) => {
  const dispatch = useDispatch();

  const akoyaLink = useSelector((state) => state.akoyaConfig.akoyaLink);
  const {
    akoyaConnection,
    consumer,
    isLastVendor,
    productRequest,
    shouldRender,
    widgetConfiguration,
    sequenceHistory
  } = useSelector(state => state.globalConfig);

  const akoyaOAuthWindow = useRef(undefined)

  const initWidget = () => {
    const widgetInitParams = { productRequestId, institution };
    dispatch(setAkoyaLoadingAction(true));
    dispatch(initializeAkoyaWidgetAction(widgetInitParams));
  };

  useEffect(() => {
    const checkInterval = setInterval(() => {
      // Do not initiate retry if about to start product request polling
      if (AKOYA_CONNECTION_SUCCESS_STATUSES.includes(akoyaConnection?.status)) {
        clearInterval(checkInterval);
        return;
      }

      const oauthWindow = akoyaOAuthWindow.current;

      if (oauthWindow && oauthWindow.closed) {
        clearInterval(checkInterval);
        dispatch(setSwitchToNextVendor());
      }
    }, 1000);

    return () => clearInterval(checkInterval);
  }, []);

  useEffect(() => {
    try {
      if (shouldRender) {
        initWidget();
      }
    } catch (e) {
      rollbar.error("Failed to init Akoya widget", e);
      onError(ApiErrorMessagesMapper(e, { isLastVendor: isLastVendor }));

      if (e.cause.statusCode === 401) {
        dispatch(toggleWidgetRender());
      }
    }
  }, []);

  useEffect(() => {
    if (
      widgetConfiguration?.features?.ucwBankSelectionMode &&
      sequenceHistory.length > 1 &&
      akoyaLink
    ) {
      loadAkoyaWidget();
    }
  }, [akoyaLink]);

  useEffect(() => {
    if (akoyaConnection?.id) {
      dispatch(setConnectionProcessingTime());
      dispatch(
        startConnectionPolling({
          connectionId: akoyaConnection?.id,
        })
      );
    }
  }, [akoyaConnection?.id]);

  useEffect(() => {
    /// Start data pull processing
    if (AKOYA_CONNECTION_SUCCESS_STATUSES.includes(akoyaConnection?.status)) {
      onSuccess(productRequest);
      dispatch(logBusinessLoginSucceeded({
        consumer: consumer?.id,
        product_request_id: productRequestId,
        connection_id: akoyaConnection?.id,
        vendor_name: akoyaConnection?.vendor_name,
        event_details: {},
        vendor_institution_id: institution?.id
      }))
      dispatch(toggleProductRequestProcessing(true));
      dispatch(setDataPullProcessingTime());
      dispatch(runProductRequestPolling({ productRequestId }));
    }
  }, [productRequestId, akoyaConnection?.status]);

  const openAkoyaWindow = () => {
    akoyaOAuthWindow.current = window.open(
      akoyaLink,
      "Institution connection",
      "menubar=no,status=no"
    );

    return (window.akoyaOAuthWindow = akoyaOAuthWindow.current);
  };

  const loadAkoyaWidget = () => {
    dispatch(
      updateAkoyaConnectionAction({
        connectionId: akoyaConnection?.id,
        productRequestId,
        status: "login_in_progress",
      })
    );

    dispatch(
      logBankSelectionEvent({
        eventPayload: {
          eventCode: "AKOYA_WIDGET_LOADED",
        },
      })
    );

    sendMessageToNative({
      event: "open_url",
      url: akoyaLink,
    });

    return openAkoyaWindow();
  };

  const handleActiveBtnClick = (e, consumer, productRequest) => {
    e.preventDefault();

    dispatch(
      logBusinessClicked({
        consumer_id: consumer?.client_consumer_id,
        product_request_id: productRequest?.id,
        vendor_name: "akoya",
        institution_id: institution?.institution_id,
      })
    );

    dispatch(
      logBankSelectionEvent({
        eventPayload: {
          eventCode: "AKOYA_CONNECT_BTN_CLICKED",
        },
      })
    );

    loadAkoyaWidget();
  };

  const activeBtn = () => {
    return (
      <ConnectMyAccountButton
        handleActiveBtnClick={(e) => {
          handleActiveBtnClick(e, consumer, productRequest);
        }}
      />
    );
  };

  if (!widgetConfiguration?.features?.bankSelection) {
    return (
      <div className="ninja-fetch-widget__body flex justify-center">
        {akoyaLink ? activeBtn() : <LoadingButton />}
      </div>
    );
  }
};

export default connect(null)(AkoyaWidget);
