import { useSelector } from "react-redux";
import {
  BANK_SELECTION,
  CONNECTION_FREEZE,
  CRITICAL_ERROR,
  MULTIPLE_INSTITUTIONS_SCREEN,
  REQUEST_COMPLETE,
  REUSE_CONNECTION,
  SWITCH_VENDOR,
} from "../../constants/masamune-widget";
import ConnectMyAccount from "./connect-my-account";
import Footer from "./footer";
import MasamuneModal from "./modal";
import ServiceMessages from "./service-messages";
import TermsAndConditions from "./terms-and-conditions";
import VendorConnectionScreen from "./vendor-connection-screen";
import NoVendorsInSequenceMessage from "./no-vendors-in-sequence-message";
import RequestCompleteMessage from "./request-complete-message";

const ModalFlow = (props) => {
  const productRequestProcessing = useSelector(
    (state) => state.globalConfig.productRequestProcessing
  );

  const showModalStatuses = [
    BANK_SELECTION,
    MULTIPLE_INSTITUTIONS_SCREEN,
    CRITICAL_ERROR,
    REUSE_CONNECTION,
    SWITCH_VENDOR,
  ];

  const flow = useSelector((state) => state.globalConfig.flow);
  const loading = useSelector((state) => state.globalConfig.isLoading);
  const processing = useSelector((state) => state.globalConfig.processing);

  const shouldRenderModal = () => {
    if (showModalStatuses.indexOf(flow) != -1) {
      return true;
    }
    return false;
  };

  if (flow === REQUEST_COMPLETE) return (
    <>
      <RequestCompleteMessage />
      <Footer />
    </>
  );

  if (flow === "NO_VENDORS_IN_SEQUENCE") {
    return (
      <>
        <NoVendorsInSequenceMessage />
        <Footer />
      </>
    );
  }

  return (
    <>
      {!productRequestProcessing && flow !== CONNECTION_FREEZE && (
        <TermsAndConditions />
      )}
      <ServiceMessages />
      {!productRequestProcessing && flow !== CONNECTION_FREEZE && (
        <VendorConnectionScreen
          {...props}
          vendorName={props.vendorName}
          institution={props.institution}
        />
      )}
      {!productRequestProcessing && flow !== CONNECTION_FREEZE && (
        <ConnectMyAccount />
      )}
      {!productRequestProcessing &&
        flow !== CONNECTION_FREEZE &&
        !processing &&
        !loading &&
        shouldRenderModal() && <MasamuneModal {...props} />}
      <Footer />
    </>
  );
};

export default ModalFlow;
