export const FAILURE_PRODUCT_REQUEST_STATUSES = ['ibv_request_expired', 'ibv_data_acquire_failed', 'generation_failed'];
export const EMAIL_FLOW_REPROCESSABLE_STATUSES = ['initiated', 'reconnect_required']
export const ACTIVE_CONNECTION_STATUSES = [
  'vendor_acquiring_data_in_progress',
  'vendor_acquiring_data_success',
  'data_pull_in_progress',
  'data_pull_success'
];
export const FINALIZED_PRODUCT_REQUEST_STATUSES = FAILURE_PRODUCT_REQUEST_STATUSES.concat(['complete'])
export const IN_PROGRESS_PRODUCT_REQUEST_STATUSES = []
