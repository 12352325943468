import { rollbarService } from "./rollbar-service";

const availableServices = {
  rollbar: rollbarService(),
};

export const MonitoringNotificationsService = (serviceName) => {
  const defaultFN = (...params) => {
    console.log(
      "this service is unavailable of turned off in this env",
      params
    );
  };
  // Return null from service itself when want to disable
  const service = availableServices[serviceName];
  const serviceInstance = service?.instance;

  // TODO: Extend this to the unified interface
  return {
    info: serviceInstance ? (...params) => serviceInstance.info(...params) : defaultFN,
    error: serviceInstance ? (...params) => serviceInstance.error(...params) : defaultFN,
    warning: serviceInstance ? (...params) => serviceInstance.warning(...params) : defaultFN,
    service: serviceInstance,
    serviceConfig: service?.config,
  }
}
