import { useSelector } from "react-redux";
import {
  CONNECTION_FREEZE,
  REQUEST_PROCESSING,
} from "../../constants/masamune-widget";
import BlankWidgetScreen from "../shared/blank-widget-screen";
import AkoyaProcessingMessage from "./akoya-processing-message";
import ProcessingMessage from "./processing-message";
import ConnectionFreezeMessage from "./connection-freeze-message";

const ServiceMessages = () => {
  const flow = useSelector((state) => state.globalConfig.flow);

  const isAkoyaLoading = useSelector((state) => state.akoyaConfig.loading);

  if (isAkoyaLoading) {
    return <AkoyaProcessingMessage />;
  }

  switch (flow) {
    case REQUEST_PROCESSING:
      return <ProcessingMessage />;
    case CONNECTION_FREEZE:
      return <ConnectionFreezeMessage />;
    default:
      return <BlankWidgetScreen />;
  }
};

export default ServiceMessages;
