import { AKOYA_IN_PROGRESS_MSG } from "../../constants/masamune-widget";

const AkoyaProcessingMessage = () => {
  return (
    <p
      id="ninja-fetch-widget__processing_message"
      className="text-center mb-10"
    >
      {AKOYA_IN_PROGRESS_MSG}
    </p>
  );
};

export default AkoyaProcessingMessage;
