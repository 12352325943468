import { useDispatch, useSelector } from "react-redux";
import { toggleBankSearchScreen } from "../../actions/bank-selection-actions";
import {
  forceShowBankSelectionScreenForProvider,
  setCurrentWidgetFlow,
  setCustomSelectedInstitutionForProvider,
  setWidgetConfiguration,
} from "../../actions/widget-actions";
import {
  BANK_SELECTION,
  NEW_CONNECTION_WITH_INSTITUTION_SELECTION,
} from "../../constants/masamune-widget";

const SelectInstitutionDialog = ({ institutions, vendor }) => {
  const dispatch = useDispatch();
  const { widgetConfiguration } = useSelector((state) => state.globalConfig);
  const { bankSelection, enableSearchOnWrongAba } =
    widgetConfiguration.features;

  const pickInstitution = (index) => {
    const currInstitution = institutions[index];

    if (currInstitution == null && bankSelection) {
      dispatch(setCurrentWidgetFlow(BANK_SELECTION));
      return dispatch(toggleBankSearchScreen(false));
    }

    if (currInstitution == null) {
      if (widgetConfiguration.features.ucwBankSelectionMode) {
        dispatch(
          setCurrentWidgetFlow(NEW_CONNECTION_WITH_INSTITUTION_SELECTION)
        );
        dispatch(
          setWidgetConfiguration({
            criticalIssueOccurred: false,
            switchToNextVendor: false,
            retryMode: true,
            currIdx: 0,
            vendorSequence: ["ucw"],
            ibvSequence: {
              routing_number: "",
              vendors: [{ vendor_name: "ucw", institutions: [] }],
            },
            currentProvider: { vendor_name: "ucw", institutions: [] },
          })
        );
      } else {
        dispatch(
          setCurrentWidgetFlow(NEW_CONNECTION_WITH_INSTITUTION_SELECTION)
        );

        setWidgetConfiguration({
          retryMode: true,
        });
      }
      return;
    }

    dispatch(setCustomSelectedInstitutionForProvider(vendor, currInstitution));
    dispatch(forceShowBankSelectionScreenForProvider(vendor));
  };

  const buildSelectOptions = () => {
    const defaultOptions = [{ value: 99999, label: "Select your option" }];

    const institutionOptions = institutions.map((institution, index) => ({
      value: index,
      label: institution.name,
    }));

    const allOptions = [...defaultOptions, ...institutionOptions];

    if (enableSearchOnWrongAba) {
      allOptions.push({
        value: 99999,
        label: "My bank is not on the list",
      });
    }

    return allOptions;
  };

  return (
    <div className="flex justify-center flex-col">
      <p className="justify-center text-center">Please select your bank</p>
      <select
        className="mt-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        onChange={(e) => pickInstitution(e.target.value)}
      >
        {buildSelectOptions().map((opt, i) => {
          return (
            <option value={opt.value} key={i}>
              {opt.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectInstitutionDialog;
