import {
  MASAMUNE_ADD_SEQUENCE_HISTORY,
  MASAMUNE_CLOSE_MODALS_AND_WIDGETS,
  MASAMUNE_CONNECTION_FREEZE,
  MASAMUNE_CONNECTION_POLLING,
  MASAMUNE_FLOW_CHANGE,
  MASAMUNE_FORCE_SHOW_BANK_SELECTION_SCREEN_FOR_PROVIDER,
  MASAMUNE_HIDE_MODAL,
  MASAMUNE_INDEX_CHANGED,
  MASAMUNE_INIT_WIDGET,
  MASAMUNE_LOG_WIDGET,
  MASAMUNE_PRODUCT_REQUEST_FAILED_TO_PROCESS,
  MASAMUNE_REUSE_EXISTING_CONNECTION,
  MASAMUNE_RUN_PRODUCT_REQUEST_POLLING,
  MASAMUNE_SET_ACCESS_TOKEN,
  MASAMUNE_SET_AKOYA_CONNECTION,
  MASAMUNE_SET_CLIENT_CALLBACK_SETUP,
  MASAMUNE_SET_CONNECTION_PROCESSING_TIME,
  MASAMUNE_SET_CONSUMER,
  MASAMUNE_SET_CRITICAL_ISSUE_OCCURRED,
  MASAMUNE_SET_CURRENT_CONSUMER,
  MASAMUNE_SET_CURRENT_VENDOR,
  MASAMUNE_SET_CURRENT_WIDGET_FLOW,
  MASAMUNE_SET_CUSTOM_SELECTED_INSTITUTION_FOR_PROVIDER,
  MASAMUNE_SET_DATA_PULL_PROCESSING_TIME,
  MASAMUNE_SET_DATA_PULL_TIMEOUT,
  MASAMUNE_SET_ERRORED_INSTITUTIONS,
  MASAMUNE_SET_FLINKS_CONNECTION,
  MASAMUNE_SET_FOUND_INSTITUTIONS,
  MASAMUNE_SET_IBV_SEQUENCE,
  MASAMUNE_SET_INTENT_URL,
  MASAMUNE_SET_IS_LANDING_PAGE,
  MASAMUNE_SET_IS_LAST_VENDOR,
  MASAMUNE_SET_PRODUCT_REQUEST,
  MASAMUNE_SET_RETRY_MODE,
  MASAMUNE_SET_SOPHTRON_CONNECTION,
  MASAMUNE_SET_SWITCH_TO_NEXT_VENDOR,
  MASAMUNE_SET_UCW_CONNECTION,
  MASAMUNE_SET_UNMOUNT_ON_COMPLETION,
  MASAMUNE_SET_WIDGET_CONFIGURATION,
  MASAMUNE_SET_YODLEE_CONNECTION,
  MASAMUNE_SHOW_MODAL,
  MASAMUNE_STOP_IBV_CONNECTION_POLLING,
  MASAMUNE_STOP_PRODUCT_REQUEST_POLLING,
  MASAMUNE_TOGGLE_LOADING,
  MASAMUNE_TOGGLE_PRODUCT_REQUEST_PROCESSING,
  MASAMUNE_TOGGLE_WIDGET_PROCESSING,
  MASAMUNE_TOGGLE_WIDGET_RENDER,
} from "../constants/masamune-widget";

export const closeModalsAndWidgets = () => {
  return {
    type: MASAMUNE_CLOSE_MODALS_AND_WIDGETS,
    payload: {
      showModal: false,
      showNewConnectionWidgets: false,
    },
  };
};

export const addSequenceToHistory = (sequence) => {
  return {
    type: MASAMUNE_ADD_SEQUENCE_HISTORY,
    payload: sequence,
  };
};

export const setRetryMode = (isRetry) => {
  return {
    type: MASAMUNE_SET_RETRY_MODE,
    payload: { retryMode: isRetry },
  };
};

export const changeMasamuneCurrentVendorIndex = (payload) => {
  return {
    type: MASAMUNE_INDEX_CHANGED,
    payload: { currVendorIdx: payload },
  };
};

export const setWidgetConfiguration = (payload) => {
  return {
    type: MASAMUNE_SET_WIDGET_CONFIGURATION,
    payload: payload,
  };
};

export const setSwitchToNextVendor = () => {
  return {
    type: MASAMUNE_SET_SWITCH_TO_NEXT_VENDOR,
  };
};

export const setCriticalIssueOccurred = () => {
  return {
    type: MASAMUNE_SET_CRITICAL_ISSUE_OCCURRED,
  };
};

export const setYodleeConnection = (connection) => {
  return {
    type: MASAMUNE_SET_YODLEE_CONNECTION,
    payload: connection,
  };
};

export const setAkoyaConnection = (connection) => {
  return {
    type: MASAMUNE_SET_AKOYA_CONNECTION,
    payload: connection,
  };
};

export const setFlinksConnection = (connection) => {
  return {
    type: MASAMUNE_SET_FLINKS_CONNECTION,
    payload: connection,
  };
};

export const setSophtronConnection = (connection) => {
  return {
    type: MASAMUNE_SET_SOPHTRON_CONNECTION,
    payload: connection,
  };
};

export const setUcwConnection = (connection) => {
  return {
    type: MASAMUNE_SET_UCW_CONNECTION,
    payload: connection,
  };
};

export const forceShowBankSelectionScreenForProvider = (provider) => {
  let payload = {};

  payload[provider] = true;

  return {
    type: MASAMUNE_FORCE_SHOW_BANK_SELECTION_SCREEN_FOR_PROVIDER,
    payload: payload,
  };
};

export const setCustomSelectedInstitutionForProvider = (
  provider,
  currInstitution
) => {
  let payload = {};

  payload[provider] = currInstitution;

  return {
    type: MASAMUNE_SET_CUSTOM_SELECTED_INSTITUTION_FOR_PROVIDER,
    payload: payload,
  };
};

export const logWidgetEvent = ({ eventPayload, vendor }) => {
  return {
    type: MASAMUNE_LOG_WIDGET,
    payload: { eventPayload, vendor },
  };
};

export const setAccessToken = (accessToken) => {
  return {
    type: MASAMUNE_SET_ACCESS_TOKEN,
    payload: accessToken,
  };
};

export const setCurrentProvider = (vendor) => {
  return {
    type: MASAMUNE_SET_CURRENT_VENDOR,
    payload: { currentProvider: vendor },
  };
};

export const setCallbackFunctions = ({ onSuccess, onError }) => {
  return {
    type: MASAMUNE_SET_CLIENT_CALLBACK_SETUP,
    payload: { onSuccess, onError },
  };
};

export const toggleWidgetProcessing = (payload) => {
  return {
    type: MASAMUNE_TOGGLE_WIDGET_PROCESSING,
    payload: payload,
  };
};

export const reuseExistingConnection = ({
  productRequestId,
  connection,
  vendor,
}) => {
  return {
    type: MASAMUNE_REUSE_EXISTING_CONNECTION,
    payload: { productRequestId, connection, vendor },
  };
};

export const toggleWidgetRender = () => {
  return {
    type: MASAMUNE_TOGGLE_WIDGET_RENDER,
  };
};

export const setIsLastVendor = () => {
  return {
    type: MASAMUNE_SET_IS_LAST_VENDOR,
  };
};

export const runProductRequestPolling = ({ productRequestId }) => {
  return {
    type: MASAMUNE_RUN_PRODUCT_REQUEST_POLLING,
    payload: { productRequestId },
  };
};

export const stopProductRequestPolling = () => {
  return {
    type: MASAMUNE_STOP_PRODUCT_REQUEST_POLLING,
  };
};

export const productRequestFailed = () => {
  return {
    type: MASAMUNE_PRODUCT_REQUEST_FAILED_TO_PROCESS,
  };
};

export const showModal = () => {
  return {
    type: MASAMUNE_SHOW_MODAL,
  };
};

export const freezeConnection = (payload) => {
  return {
    type: MASAMUNE_CONNECTION_FREEZE,
    payload: payload,
  };
};

export const hideModal = () => {
  return {
    type: MASAMUNE_HIDE_MODAL,
  };
};

export const stopConnectionPolling = () => {
  return {
    type: MASAMUNE_STOP_IBV_CONNECTION_POLLING,
  };
};

export const toggleLoading = () => {
  return {
    type: MASAMUNE_TOGGLE_LOADING,
  };
};

export const setDataPullTimeout = (timeout) => {
  return {
    type: MASAMUNE_SET_DATA_PULL_TIMEOUT,
    payload: timeout,
  };
};

export const setUnmountOnCompletion = (unmount) => {
  return {
    type: MASAMUNE_SET_UNMOUNT_ON_COMPLETION,
    payload: unmount,
  };
};

export const setProductRequest = (productRequest) => {
  return {
    type: MASAMUNE_SET_PRODUCT_REQUEST,
    payload: productRequest,
  };
};

export const toggleProductRequestProcessing = (isProcessing) => {
  return {
    type: MASAMUNE_TOGGLE_PRODUCT_REQUEST_PROCESSING,
    payload: isProcessing,
  };
};

export const setDataPullProcessingTime = () => {
  return {
    type: MASAMUNE_SET_DATA_PULL_PROCESSING_TIME,
  };
};

export const setConnectionProcessingTime = () => {
  return {
    type: MASAMUNE_SET_CONNECTION_PROCESSING_TIME,
  };
};

export const setConsumer = (consumer) => {
  return {
    type: MASAMUNE_SET_CONSUMER,
    payload: consumer,
  };
};

export const setIntentUrl = (intentUrl) => {
  return {
    type: MASAMUNE_SET_INTENT_URL,
    payload: intentUrl,
  };
};
// TODO: Revise
export const setCurrentConsumer = (payload) => {
  return {
    type: MASAMUNE_SET_CURRENT_CONSUMER,
    payload: payload,
  };
};

export const initWidget = (payload) => {
  return {
    type: MASAMUNE_INIT_WIDGET,
    payload: payload,
  };
};

export const setIbvSequence = (payload) => {
  return {
    type: MASAMUNE_SET_IBV_SEQUENCE,
    payload: payload,
  };
};

export const setCurrentWidgetFlow = (flowName) => {
  return {
    type: MASAMUNE_SET_CURRENT_WIDGET_FLOW,
    payload: { flow: flowName },
  };
};

export const setWidgetFlow = () => {
  return {
    type: MASAMUNE_FLOW_CHANGE,
  };
};

export const setFoundInstitutions = (institutions) => {
  return {
    type: MASAMUNE_SET_FOUND_INSTITUTIONS,
    payload: { foundInstitutions: institutions },
  };
};

export const startConnectionPolling = ({ connectionId }) => {
  return {
    type: MASAMUNE_CONNECTION_POLLING,
    payload: { connectionId },
  };
};

export const setErroredInstitutions = (institutionNames) => {
  return {
    type: MASAMUNE_SET_ERRORED_INSTITUTIONS,
    payload: { erroredInstitutions: institutionNames },
  };
};

export const setIsLandingPage = (isLandingPage = false) => {
  return {
    type: MASAMUNE_SET_IS_LANDING_PAGE,
    payload: { isLandingPage },
  };
};
