import {
  calculateChecksum,
  getIbvSequence,
  normalizedIbvSequence,
} from "./masamune-widget-utils";

export const getNormalizedSequence = async ({
  consumer,
  vendorInstitutionId,
  routingNumber,
  accessToken,
}) => {
  const ibvSequenceResponse = await getIbvSequence({
    consumer,
    vendorInstitutionId,
    routingNumber,
    accessToken,
  });

  return normalizedIbvSequence(ibvSequenceResponse);
};

export const sequencesAreEqual = (sequence1, sequence2) => {
  const seq1 = calculateChecksum(JSON.stringify(sequence1));
  const seq2 = calculateChecksum(JSON.stringify(sequence2));

  return seq1 === seq2;
};
