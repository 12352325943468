import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BANK_SELECTION,
  CRITICAL_ERROR,
  MULTIPLE_INSTITUTIONS_SCREEN,
  REUSE_CONNECTION,
  SWITCH_VENDOR,
  WIDGET_LOADING,
} from "../../constants/masamune-widget";
import BankSearchScreen from "./bank-search-screen";
import LoadingWidgetScreen from "./loading-widget-screen";
import RetryDialog from "./retry-dialog";
import ReuseConnectionDialog from "./reuse-connection-dialog";
import SelectInstitutionDialog from "./select-institution-dialog";
import SomethingWentWrongDialog from "./something-went-wrong-dialog";
import { logBusinessBankSelectionOpened } from "../../actions/business-actions";

const ModalBody = (props) => {
  const flow = useSelector((state) => state.globalConfig.flow);
  const showModal = useSelector((state) => state.globalConfig.showModal);
  const productRequest = useSelector((state) => state.globalConfig.productRequest);
  const dispatch = useDispatch();

  useEffect(() => {
    if (flow == BANK_SELECTION && showModal) {
      dispatch(logBusinessBankSelectionOpened({ product_request_id: productRequest?.product_request_id }));
    }
  }, [flow, showModal]);

  switch (flow) {
    case WIDGET_LOADING:
      return <LoadingWidgetScreen />;
    case BANK_SELECTION:
      return <BankSearchScreen {...props} />;
    case SWITCH_VENDOR:
      return <RetryDialog />;
    case CRITICAL_ERROR:
      return <SomethingWentWrongDialog {...props} />;
    case REUSE_CONNECTION:
      return <ReuseConnectionDialog {...props} />;
    case MULTIPLE_INSTITUTIONS_SCREEN:
      return (
        <SelectInstitutionDialog
          institutions={props.foundInstitutions}
          vendor={props.vendorName}
        />
      );
    default:
      return <LoadingWidgetScreen />;
  }
};

export default ModalBody;
