import { Modal } from "flowbite";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSearchResults,
  logBankSelectionEvent,
  toggleBankSearchScreen,
} from "../../actions/bank-selection-actions";
import { setWidgetConfiguration } from "../../actions/widget-actions";
import ModalBody from "./modal-body";

const MasamuneModal = (props) => {
  const dispatch = useDispatch();
  const flow = useSelector((state) => state.globalConfig.flow);
  const showModal = useSelector((state) => state.globalConfig.showModal);

  const container = document.getElementById(props.container_id);
  const shadowRoot = container.shadowRoot;

  const handleClose = () => {
    if (window.modalInstance) {
      window.modalInstance.hide();
      dispatch(toggleBankSearchScreen(false));
    }
  };

  const onClose = () => {
    dispatch(clearSearchResults());
    dispatch(toggleBankSearchScreen(false));
    dispatch(
      setWidgetConfiguration({
        // currVendorIdx: 0,
        criticalIssueOccurred: false,
        showModal: false,
      })
    );

    dispatch(
      logBankSelectionEvent({
        eventPayload: {
          eventCode: "MODAL_CLOSED",
          currentFlow: flow,
        },
      })
    );
  };

  useEffect(() => {
    const options = {
      placement: "center",
      backdrop: "dynamic",
      closable: true,
      onHide: onClose,
    };
    const $targetEl = shadowRoot.getElementById("popup-modal");
    const modalInstance = new Modal($targetEl, options);
    window.modalInstance = modalInstance;
  }, []);

  useEffect(() => {
    if (window.modalInstance && showModal) {
      window.modalInstance.show();
    } else if (window.modalInstance && !showModal) {
      window.modalInstance.hide();
    }
  }, [showModal]);

  return (
    <div
      id="popup-modal"
      tabIndex="-1"
      className="fixed top-0 left-0 right-0 hidden overflow-x-hidden overflow-y-auto md:inset-0 max-h-full bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-[2000]"
    >
      <div className="relative w-[90%] lg:max-w-[600px] max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex">
            <button
              type="button"
              className="relative top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={handleClose}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-6 space-y-6 text-center">
            <ModalBody {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasamuneModal;
