import { spawn } from "redux-saga/effects";
import akoyaSagas from "./akoya-sagas";
import bankSearchSagas from "./bank-search-sagas";
import flinksWidgetSagas from "./flinks-widget-sagas";
import masamuneWidgetSagas from "./masamune-widget-sagas";
import sophtronWidgetSagas from "./sophtron-widget-sagas";
import yodleeWidgetSagas from "./yodlee-widget-sagas";
import ucwWidgetSagas from "./ucw-widget-sagas";

export default function* rootSaga() {
  yield spawn(bankSearchSagas);
  yield spawn(flinksWidgetSagas);
  yield spawn(yodleeWidgetSagas);
  yield spawn(sophtronWidgetSagas);
  yield spawn(masamuneWidgetSagas);
  yield spawn(akoyaSagas);
  yield spawn(ucwWidgetSagas);
}
