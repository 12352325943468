export const YODLEE_UNPROCESSABLE_ERROR_CODES = [
  "BETA_SITE_DEV_IN_PROGRESS",
  "DATA_RETRIEVAL_FAILED",
  "STATUS_SITE_UNAVAILABLE",
  "PROPERTY_VALUE_NOT_AVAILABLE",
  "REQUEST_TIME_OUT",
  "SITE_BLOCKING_ERROR",
  "UNEXPECTED_SITE_ERROR",
  "SITE_NOT_SUPPORTED",
  "SITE_UNAVAILABLE",
  "TECH_ERROR",
  "DATASET_NOT_SUPPORTED",
  "SYSTEM_ERROR",
];

export const YODLEE_INFO_CODES = ['N501', 'N503']
export const YODLEE_WARNING_CODES = ['E100']
export const YODLEE_WARNING_ADDITIONAL_STATUSES = ['TECH_ERROR']

export const YODLEE_WIDGET_UPDATE_DELAY = 60000

//ACTIONS
export const YODLEE_WIDGET_INIT = "YODLEE/WIDGET_INIT";
export const YODLEE_HANDLE_YODLEE_WIDGET_EVENT =
  "YODLEE/HANDLE_YODLEE_WIDGET_EVENT";
export const YODLEE_HANDLE_YODLEE_WIDGET_SUCCESS =
  "YODLEE/HANDLE_YODLEE_WIDGET_SUCCESS";
export const YODLEE_HANDLE_YODLEE_WIDGET_CLOSE =
  "YODLEE/HANDLE_YODLEE_WIDGET_CLOSE";
export const YODLEE_HANDLE_YODLEE_WIDGET_ERROR =
  "YODLEE/HANDLE_YODLEE_WIDGET_ERROR";
export const YODLEE_CONNECTION_UPDATE = "YODLEE/CONNECTION_UPDATE";
export const YODLEE_WIDGET_CONFIG_UPDATE = "YODLEE/WIDGET_CONFIG_UPDATE";
export const YODLEE_GET_WIDGET_CONFIGURATION =
  "YODLEE/GET_WIDGET_CONFIGURATION";
export const YODLEE_INIT_WIDGET = "YODLEE/INIT_WIDGET";
export const YODLEE_SET_YODLEE_WIDGET_CONFIGURATION =
  "YODLEE/SET_YODLEE_WIDGET_CONFIGURATION";
export const YODLEE_CONFIG_CANCEL_STATUS_POLLING =
  "YODLEE/CONFIG_CANCEL_STATUS_POLLING";
export const YODLEE_SHOULD_REUSE_CONNECTION = "YODLEE/SHOULD_REUSE_CONNECTION";
export const YODLEE_WIDGET_LOADING = "YODLEE/WIDGET_LOADING";
