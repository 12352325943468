const RequestCompleteMessage = () => {
  return (
    <p
      id="ninja-fetch-widget__request_complete_message"
      className="text-center mb-10"
    >
      We have successfully completed your request!
    </p>
  );
};

export default RequestCompleteMessage;
