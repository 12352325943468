import { useSelector } from "react-redux";
import NotMyBankLink from "./not-my-bank-link";

const ConnectMyAccountButton = ({ handleActiveBtnClick }) => {
  const {
    currVendorIdx,
    foundInstitutions,
    ibvSequence,
    widgetConfiguration
  } = useSelector(state => state.globalConfig);

  const allowBankSearch = (
    foundInstitutions[0] && (
      widgetConfiguration.features.enableSearchOnWrongAba ||
      (
        !widgetConfiguration.features.disableAkoyaSearch &&
        ibvSequence.vendors[currVendorIdx]?.vendor_name === "akoya"
      )
    )
  );

  return (
    <div className="text-center">
      <button
        onClick={handleActiveBtnClick}
        className="py-4 px-8 lg:py-2 lg:px-4 rounded-full text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
      >
        {foundInstitutions[0] ? `Connect to ${foundInstitutions[0].name}` : <>Connect my Account</>}
      </button>
      <br />
      {allowBankSearch && <NotMyBankLink />}
    </div>
  );
}

export default ConnectMyAccountButton;
