import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentWidgetFlow,
  setWidgetConfiguration,
} from "../../actions/widget-actions";
import { NEW_CONNECTION_WITH_INSTITUTION_SELECTION } from "../../constants/masamune-widget";

const NotMyBankLink = () => {
  const dispatch = useDispatch();
  const { widgetConfiguration } = useSelector((state) => state.globalConfig);

  const showBankSearch = () => {
    if (widgetConfiguration.features.ucwBankSelectionMode) {
      dispatch(setCurrentWidgetFlow(NEW_CONNECTION_WITH_INSTITUTION_SELECTION));
      dispatch(
        setWidgetConfiguration({
          criticalIssueOccurred: false,
          switchToNextVendor: false,
          retryMode: true,
          currIdx: 0,
          vendorSequence: ["ucw"],
          ibvSequence: {
            routing_number: "",
            vendors: [{ vendor_name: "ucw", institutions: [] }],
          },
          currentProvider: { vendor_name: "ucw", institutions: [] },
        })
      );
    } else {
      dispatch(
        setWidgetConfiguration({
          switchToNextVendor: true,
          retryFlowInstantBankSearch: true,
        })
      );
    }
  };

  const handleBankSearchClick = (e) => {
    e.preventDefault();
    showBankSearch();
  };

  return (
    <p className="text-center mt-5">
      <a
        href="#"
        onClick={(e) => handleBankSearchClick(e)}
        className="text-blue-500 underline hover:text-blue-700"
      >
        That is not my bank. Click here to select a different bank
      </a>
    </p>
  );
};

export default NotMyBankLink;
