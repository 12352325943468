import { connect, useDispatch, useSelector } from "react-redux";
import {
  reuseExistingConnection,
  setCurrentWidgetFlow,
  setRetryMode,
  setWidgetConfiguration,
} from "../../actions/widget-actions";
import { NEW_CONNECTION_WITH_INSTITUTION_SELECTION } from "../../constants/masamune-widget";
import { resolveInstitution } from "../../shared/masamune-widget-utils";
import {
  logBusinessNewConnectionClicked,
  logBusinessReuseClicked,
} from "../../actions/business-actions";

const ReuseConnectionDialog = (props) => {
  const dispatch = useDispatch();
  const globalConfig = useSelector((state) => state.globalConfig);
  const currentProvider = globalConfig.currentProvider;
  const vendorName = currentProvider?.vendor_name;
  const connection = globalConfig.ibvConnections[vendorName];
  const productRequestId =
    props.product_request_id ||
    props.productRequestId ||
    globalConfig.productRequest?.product_request_id;

  const customInstitutionSelected =
    globalConfig.customInstitutionSelected[vendorName];
  const widgetConfiguration = useSelector(
    (state) => state.globalConfig.widgetConfiguration
  );

  const institution = resolveInstitution(
    customInstitutionSelected,
    globalConfig.ibvSequence,
    globalConfig.currVendorIdx
  );

  const handleUcwBankSearch = () => {
    dispatch(setCurrentWidgetFlow(NEW_CONNECTION_WITH_INSTITUTION_SELECTION));

    dispatch(
      setWidgetConfiguration({
        criticalIssueOccurred: false,
        switchToNextVendor: false,
        retryMode: true,
        currIdx: 0,
        vendorSequence: ["ucw"],
        ibvSequence: {
          routing_number: "",
          vendors: [{ vendor_name: "ucw", institutions: [] }],
        },
        currentProvider: { vendor_name: "ucw", institutions: [] },
      })
    );
  };

  const handleNewConnectionInit = (e, productRequestId) => {
    e.preventDefault();

    dispatch(
      logBusinessNewConnectionClicked({ product_request_id: productRequestId })
    );

    if (widgetConfiguration.features.ucwBankSelectionMode) {
      handleUcwBankSearch();
      return;
    }

    if (vendorName === "akoya") {
      dispatch(
        setWidgetConfiguration({
          switchToNextVendor: true,
          retryFlowInstantBankSearch: true,
        })
      );
    } else {
      dispatch(setRetryMode(true));
      dispatch(setCurrentWidgetFlow(NEW_CONNECTION_WITH_INSTITUTION_SELECTION));
    }
  };

  const handleReuseExistingConnection = (
    e,
    connection,
    productRequestId,
    vendorName
  ) => {
    e.preventDefault();

    dispatch(
      logBusinessReuseClicked({
        product_request_id: productRequestId,
        connection_id: connection?.id,
        vendor_name: vendorName,
      })
    );

    dispatch(
      reuseExistingConnection({
        productRequestId: productRequestId,
        connection: connection,
        vendor: vendorName,
      })
    );
  };

  return (
    <div className="flex justify-center flex-col">
      <p className="justify-center text-center">
        You already have an active connection to <b>{institution.name}</b> with
        us. Would you like to connect to a different institution?
      </p>
      <div
        className={"flex flex-col justify-center items-center space-y-4 mt-5"}
      >
        <button
          onClick={(e) =>
            handleReuseExistingConnection(
              e,
              connection,
              productRequestId,
              vendorName
            )
          }
          className="py-4 px-8 min-w-[250px] max-w-[350px] lg:py-2 lg:px-4 rounded-full text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 items-baseline"
        >
          Use {institution.name}
        </button>

        <button
          onClick={(e) => handleNewConnectionInit(e, productRequestId)}
          className="py-4 px-8 min-w-[250px] max-w-[350px] lg:py-2 lg:px-4 rounded-full text-white font-bold bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 items-baseline"
        >
          Connect New Institution
        </button>
      </div>
    </div>
  );
};

export default connect(null)(ReuseConnectionDialog);
