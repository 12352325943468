import debounce from "lodash.debounce";
import { useEffect, useMemo, useRef, useState } from "react";

import DefaultBankImage from "../../assets/bank_default_image.png";
import ApiClient from "../../services/api-client";

import { useDispatch, useSelector } from "react-redux";
import {
  clearSearchResults,
  logBankSelectionEvent,
  setFoundInstitutions,
  setNotSupportedInstitution,
  setQuery,
  setTopInstitutions,
  toggleBankSearchScreen,
  toggleBankSearchScreenLoading,
} from "../../actions/bank-selection-actions";
import {
  changeMasamuneCurrentVendorIndex,
  setIbvSequence,
  setWidgetConfiguration,
  toggleWidgetProcessing,
  toggleWidgetRender,
} from "../../actions/widget-actions";

import {
  calculateChecksum,
  getIbvSequence,
  normalizedIbvSequence,
} from "../../shared/masamune-widget-utils";
import BlankWidgetScreen from "../shared/blank-widget-screen";
import { logBusinessBankSelectionSelected } from "../../actions/business-actions";
import LoadingSvg from "../../assets/loading.svg";

const BankSearchScreen = (props) => {
  const accessToken = useSelector((state) => state.globalConfig.accessToken);
  const consumer = useSelector((state) => state.globalConfig.consumer);
  const currVendorIdx = useSelector(
    (state) => state.globalConfig.currVendorIdx
  );
  const bankSearchScreenConfig = useSelector(
    (state) => state.bankSelectionConfig
  );
  const currentSequence = useSelector(
    (state) => state.globalConfig.ibvSequence
  );
  const productRequest = useSelector((state) => state.globalConfig.productRequest);

  const dispatch = useDispatch();
  let institutionsToDisplay = [];

  if (
    !bankSearchScreenConfig.searchLoading &&
    bankSearchScreenConfig.topInstitutions &&
    bankSearchScreenConfig.query === ""
  ) {
    institutionsToDisplay = bankSearchScreenConfig.topInstitutions;
  } else {
    institutionsToDisplay = bankSearchScreenConfig.foundInstitutions;
  }

  useEffect(() => {
    dispatch(toggleBankSearchScreen(true));

    dispatch(
      logBankSelectionEvent({
        eventPayload: {
          eventCode: "BANK_SEARCH_LOADED",
        },
      })
    );

    (async () => {
      try {
        const institutions = await ApiClient.topInstitutions(accessToken);

        dispatch(setTopInstitutions(institutions));
      } catch (e) {
        if (e.cause.statusCode === 401) {
          dispatch(toggleWidgetRender());
        }
      }
    })();
  }, []);

  const saveQueryToLog = (query, institutions) => {
    dispatch(
      logBankSelectionEvent({
        eventPayload: {
          eventCode: "SEARCH_QUERY_ENTERED",
          details: {
            query: query,
            foundInstitutions: institutions,
          },
        },
      })
    );
  };

  const handleSearch = async (e) => {
    const inputValue = e.target.value;
    if (inputValue === "") {
      return dispatch(clearSearchResults());
    }

    dispatch(setQuery(inputValue));
    dispatch(toggleBankSearchScreenLoading());
    try {
      const results = await ApiClient.searchInstitutions(
        inputValue,
        accessToken
      );
      dispatch(setFoundInstitutions(results));
      saveQueryToLog(inputValue, results);
      dispatch(toggleBankSearchScreenLoading());
    } catch (e) {
      if (e.cause.statusCode === 401) {
        dispatch(toggleWidgetRender());
      }
    }
  };

  const handleInstitutionSelection = async (e, institution, productRequest) => {
    e.preventDefault();
    // Track event when customer selected an institution

    dispatch(logBusinessBankSelectionSelected({
      product_request_id: productRequest?.id,
      vendor_institution_id: institution.vendor_institution_id
    }))

    dispatch(
      logBankSelectionEvent({
        eventPayload: {
          eventCode: "INSTITUTION_SELECTED",
          details: institution,
        },
      })
    );
    dispatch(toggleWidgetProcessing(true));

    const ibvSequenceResponse = await getIbvSequence(
      props,
      consumer,
      institution.vendor_institution_id
    );

    const newSequenceResponse = await calculateChecksum(
      JSON.stringify(ibvSequenceResponse)
    );
    const existingSequenceResponse = await calculateChecksum(
      JSON.stringify(currentSequence)
    );
    const vendorIndex =
      newSequenceResponse !== existingSequenceResponse ? 0 : currVendorIdx;

    const normalizedIbvSequenceResponse =
      normalizedIbvSequence(ibvSequenceResponse);
    dispatch(setIbvSequence(normalizedIbvSequenceResponse));
    dispatch(changeMasamuneCurrentVendorIndex(vendorIndex));

    const flattenedInstitutions =
      normalizedIbvSequenceResponse.ibvSequence.vendors
        .map((o) => {
          return o.institutions;
        })
        .flat(1);

    if (flattenedInstitutions.length === 0) {
      let notSupportedPayload = {};
      notSupportedPayload[institution.vendor_institution_id] = institution;

      dispatch(setNotSupportedInstitution(notSupportedPayload));
      dispatch(
        logBankSelectionEvent({
          eventPayload: {
            eventCode: "INSTITUTION_NOT_SUPPORTED",
            details: institution,
          },
        })
      );
    }

    /// Make widgets render automatically
    dispatch(
      setWidgetConfiguration({
        retryWithBankSelection: false,
        showNewConnectionWidgets: true,
      })
    );

    dispatch(setQuery(""));
    dispatch(toggleWidgetProcessing(false));
  };

  const debouncedHandleSearch = useMemo(() => debounce(handleSearch, 1000), []);

  const institutionNotFoundScreen = () => {
    return (
      <div className="text-base text-center text-bold text-gray-600 mt-10">
        We are unable to find an institution matching your search terms.
      </div>
    );
  };

  const searchProgressSpinner = () => {
    return (
      <div>
        <div role="status" className="items-center flex mt-6 justify-center">
          {LoadingSvg}
          <span className="sr-only">Loading...</span>
        </div>
        <p className="text-center mt-2 font-normal text-gray-500">
          Searching...
        </p>
      </div>
    );
  };

  const resolveItemLogo = (item) => {
    if (item.edge_logo) return item.edge_logo;
    if (item.logo_url === 'https://yodlee-1.hs.llnwd.net/v1/LOGO/LOGO_Default.SVG') return DefaultBankImage;
    if (item.logo_url) return item.logo_url;
    return DefaultBankImage;
  };

  const mappingInfoSection = (vendorInstitutionId) => {
    const unsupportedMappings = bankSearchScreenConfig.unsupportedMappings;

    if (unsupportedMappings[vendorInstitutionId]) {
      return (
        <span className="inline-flex items-center bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
          <span className="w-2 h-2 mr-1 bg-red-500 rounded-full"></span>
          Temporary unavailable
        </span>
      );
    }
  };

  const SearchBankList = () => {
    const [scrolled, setScrolled] = useState(false);
    const [hasVerticalScrollBar, setHasVerticalScrollBar] = useState(false);
    const divRef = useRef();

    useEffect(() => {
      if (divRef.current.scrollHeight > divRef.current.clientHeight)
        setHasVerticalScrollBar(true);
    }, []);

    return (
      <>
        <div
          ref={divRef}
          className="overflow-y-auto max-h-[60rem] lg:max-h-[32rem]"
          onScroll={() => setScrolled(true)}
        >
          <ul className="mt-[16px]">
            {institutionsToDisplay.map((item, idx) => (
              <li
                key={idx.toString()}
                className={"mb-[20px]"}
                onClick={(e) => handleInstitutionSelection(e, item, productRequest)}
              >
                <div className="flex flex-row bg-white hover:bg-gray-100 items-center rounded-2xl">
                  <img
                    className={"object-contain w-20 h-20"}
                    src={resolveItemLogo(item)}
                    alt={item.name}
                  />
                  <div className="ml-10 font-normal tracking-tight text-gray-900 text-lg lg:text-base">
                    <h5>{item.name}</h5>
                    {mappingInfoSection(item.vendor_institution_id)}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="h-2 text-center text-gray-400">
          {!scrolled && hasVerticalScrollBar && "↓ Scroll for more"}
        </div>
      </>
    );
  };

  const resolveSearchInstitutionsContent = () => {
    if (bankSearchScreenConfig.searchLoading) {
      return searchProgressSpinner();
    }

    if (institutionsToDisplay.length > 0) {
      return <SearchBankList />;
    }

    return institutionNotFoundScreen();
  };

  if (!bankSearchScreenConfig.showSearchScreen) {
    return <BlankWidgetScreen />;
  }

  return (
    <div>
      <h1 className="mb-5 text-xl text-center font-medium lg:text-lg">
        Search for your financial institution
      </h1>
      <label
        htmlFor="default-search"
        className="mb-2 text-lg font-lg text-gray-900 sr-only dark:text-white lg:text-base"
      >
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="block w-full p-4 pl-10 text-lg lg:text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          onChange={debouncedHandleSearch}
          placeholder="Search Institutions"
          required
        />
      </div>
      {resolveSearchInstitutionsContent()}
    </div>
  );
};

export default BankSearchScreen;
