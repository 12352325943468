import { spawn, takeEvery, select, put } from "redux-saga/effects";
import { LOG_BANK_SEARCH_EVENT } from "../constants/bank-selection";
import { logWidgetEvent } from "../actions/widget-actions";

function* handleBankSearchLogEventWorker(action) {
  const { eventPayload } = action.payload;
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;
  const vendor = "fetch";

  yield put(logWidgetEvent({ eventPayload, accessToken, vendor }));
}

function* watchBankSearchEvents() {
  yield takeEvery(LOG_BANK_SEARCH_EVENT, handleBankSearchLogEventWorker);
}

export default function* bankSearchSagas() {
  yield spawn(watchBankSearchEvents);
}
