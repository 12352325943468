import ApiClient from "../services/api-client";

import {
  ACTIVE_CONNECTION_STATUSES,
  EMAIL_FLOW_REPROCESSABLE_STATUSES,
  FAILURE_PRODUCT_REQUEST_STATUSES,
  FINALIZED_PRODUCT_REQUEST_STATUSES,
} from "../constants/global";

const buf2hex = (buffer) =>
  [...new Uint8Array(buffer)]
    .map((x) => x.toString(16).padStart(2, "0"))
    .join("");

export const calculateChecksum = async (data) => {
  const buffer = new TextEncoder("utf-8").encode(data);
  const hash = await crypto.subtle.digest("SHA-256", buffer);
  return buf2hex(hash);
};

export const getConsumerInfo = async (consumer_id, accessToken) => {
  return await ApiClient.getConsumerInfo(consumer_id, accessToken);
};

export const createConsumer = async (params, accessToken) => {
  return await ApiClient.createConsumer(params, accessToken);
};

export const getConnectionById = async (connectionId, accessToken) => {
  return await ApiClient.getConnectionById(connectionId, accessToken);
};

export const isProductRequestFrozen = async (productRequestId, globalConfig) => {
  const accessToken = globalConfig.accessToken;
  const { freeze } = await ApiClient.isProductRequestFrozen(productRequestId, accessToken);
  return freeze && !globalConfig.productRequestProcessing;
};

export const getWidgetConfiguration = async ({
  vendor,
  connectionId,
  vendorInstitutionId,
  productRequestId,
  accessToken,
}) => {
  return await ApiClient.getWidgetConfig(
    {
      vendor: vendor,
      connection_id: connectionId,
      vendor_institution_id: vendorInstitutionId,
      product_request_id: productRequestId,
    },
    accessToken
  );
};

export const logWidgetParamsLog = async (params, accessToken) => {
  return await ApiClient.logVendorWidgetEvent(params, accessToken);
};

export const getIbvSequence = async ({
  routingNumber,
  consumer,
  vendorInstitutionId,
  accessToken,
}) => {
  const params = {
    consumer_id: consumer.client_consumer_id,
    client_id: consumer.client_id.toString(),
    routing_number: routingNumber,
    vendor_institution_id: vendorInstitutionId,
    custom_sequence: [],
  };

  return await ApiClient.getIbvSequence(params, accessToken);
};

export const setNotificationSettings = async (webhook_delay, accessToken) => {
  const params = {
    notifications_settings: {
      webhooks_delay: webhook_delay,
    },
  };

  return await ApiClient.setNotificationSettings(params, accessToken);
};

export const updateNotificationSettings = async (
  webhook_delay,
  settingId,
  accessToken
) => {
  const params = {
    notifications_settings: {
      webhooks_delay: webhook_delay,
    },
  };

  return await ApiClient.updateNotificationSettings(
    params,
    settingId,
    accessToken
  );
};

export const getNotificationSettings = async (accessToken) => {
  return await ApiClient.getNotificationSettings(accessToken);
};

export const createOrUpdateWebhooksDelay = async (
  webhook_delay,
  accessToken
) => {
  const existingSetting = await getNotificationSettings(accessToken);
  const existingSettingId = existingSetting?.id;

  if (existingSettingId) {
    return await updateNotificationSettings(
      webhook_delay,
      existingSettingId,
      accessToken
    );
  } else {
    return await setNotificationSettings(webhook_delay, accessToken);
  }
};

export const normalizedIbvSequence = (sequenceResponse) => {
  const vendorSequence = sequenceResponse.vendors.map(
    (vendor) => vendor.vendor_name
  );
  const yodleeConnection = getIbvConnectionDetails(sequenceResponse, "yodlee");
  const sophtronConnection = getIbvConnectionDetails(
    sequenceResponse,
    "sophtron"
  );
  const flinksConnection = getIbvConnectionDetails(sequenceResponse, "flinks");
  const akoyaConnection = getIbvConnectionDetails(sequenceResponse, "akoya");
  const ucwConnection = getIbvConnectionDetails(sequenceResponse, "ucw");

  return {
    vendorSequence: vendorSequence,
    ibvSequence: sequenceResponse,
    yodleeConnection: yodleeConnection,
    sophtronConnection: sophtronConnection,
    flinksConnection: flinksConnection,
    akoyaConnection: akoyaConnection,
    ucwConnection: ucwConnection,
    ibvConnections: {
      flinks: flinksConnection,
      yodlee: yodleeConnection,
      sophtron: sophtronConnection,
      akoya: akoyaConnection,
      ucw: ucwConnection,
    },
  };
};

export const getProductRequest = async (productRequestId, accessToken) => {
  return await ApiClient.getProductRequest(productRequestId, {}, accessToken);
};

export const createProductRequest = async (consumerData, accessToken) => {
  return ApiClient.createProductRequest(consumerData, accessToken);
};

export const findOrCreateProductRequest = async (
  consumerData,
  productRequestId,
  accessToken
) => {
  if (productRequestId) {
    return await getProductRequest(productRequestId, accessToken);
  }

  return createProductRequest(
    { consumer_data: consumerData, ...consumerData },
    accessToken
  );
};

export const shouldKeepVendorAccessKeyAlive = (
  tokenExpiresAtInMilliseconds
) => {
  if (tokenExpiresAtInMilliseconds) {
    let time_now = Date.now();

    return tokenExpiresAtInMilliseconds < time_now;
  }
};

export const processingTimedOut = (
  startTime,
  threshold = window.NinjaFetchWidget.processingTimeoutThresholdInSec
) => {
  const currentTime = new Date().getTime() / 1000;
  const timeDiff = currentTime - startTime;

  return timeDiff >= threshold;
};

export const connectionProcessingTimedOut = (startTime, threshold) => {
  const currentTime = new Date().getTime() / 1000;
  const timeDiff = currentTime - startTime;

  return timeDiff >= threshold;
};

export const ibvConnectionEstablished = (connection) => {
  return connection.status === "data_pull_success";
};

export const ibvConnectionFailed = (connection) => {
  return (
    [
      "login_failed",
      "user_input_required",
      "data_pull_failed",
      "replaced",
    ].indexOf(connection.status) > -1
  );
};

const isProductRequestFinalized = (productRequest) => {
  return FINALIZED_PRODUCT_REQUEST_STATUSES.includes(
    productRequest.ibv_report_status
  );
};

const isProductRequestEmailFlowReprocessable = (productRequest) => {
  return EMAIL_FLOW_REPROCESSABLE_STATUSES.includes(
    productRequest.ibv_report_status
  );
};

export const shouldReuseExistingConnection = async (
  existingConnection,
  existingProductRequestId,
  accessToken
) => {
  if (!existingProductRequestId || !existingConnection || !accessToken) {
    return;
  }
  const productRequest = await getProductRequest(
    existingProductRequestId,
    accessToken
  );
  if (existingConnection && productRequest) {
    // Don't reuse flinks connections
    if (existingConnection.vendor_name === "flinks") {
      return;
    }
    const productRequestFits =
      isProductRequestEmailFlowReprocessable(productRequest) ||
      isProductRequestFinalized(productRequest);
    if (existingConnection.vendor_name === "akoya") {
      return existingConnection?.status === "data_pull_success";
    }

    return (
      productRequestFits && existingConnection?.status === "data_pull_success"
    );
  }
};

export const getLatestProductRequestFromCurrentConsumer = (
  activeConnections,
  vendorName
) => {
  if (!activeConnections) {
    return;
  }

  const connectionByVendor = activeConnections.filter(
    (connection) => connection.vendor_name === vendorName
  );

  if (connectionByVendor && connectionByVendor[0]?.latest_product_request) {
    return connectionByVendor[0].latest_product_request;
  }
};

export const sendMessageToNative = (message) => {
  let target =
    window.ReactNativeWebView ||
    window.YFlutterWebView ||
    window.YWebViewHandler ||
    window.webkit?.messageHandlers?.YWebViewHandler;
  if (target) {
    target.postMessage(JSON.stringify(message));
  }
};

export const pullIbvData = async (
  ibvConnectionId,
  productRequestId,
  vendor,
  accessToken
) => {
  return await ApiClient.pullIbvData(
    {
      connection_id: ibvConnectionId,
      product_request_id: productRequestId,
      vendor: vendor,
    },
    accessToken
  );
};

export const isLastVendorInTheSequence = (vendorSequence, currentVendorIdx) => {
  return (
    vendorSequence.length - 1 === currentVendorIdx ||
    vendorSequence.length - 1 < currentVendorIdx
  );
};

export const getSequenceInstitution = (
  sequenceResponse,
  current_vendor_idx
) => {
  const currentVendorSettings = sequenceResponse.vendors[current_vendor_idx];

  return currentVendorSettings.institutions[0];
};

export const resolveInstitution = (
  customInstitutionSelected,
  ibvSequence,
  currentVendorIdx
) => {
  if (!ibvSequence) {
    return;
  }
  if (customInstitutionSelected) {
    return customInstitutionSelected;
  }

  return getSequenceInstitution(ibvSequence, currentVendorIdx);
};

export const getIbvConnectionDetails = (vendorSequence, vendorName) => {
  let ibvConnection = null;
  let vendorResponse = vendorSequence.vendors.filter(
    (vendor) => vendor.vendor_name === vendorName
  );
  if (vendorResponse && vendorResponse[0]) {
    let institutionWithConnection = vendorResponse[0].institutions.filter(
      (institution) => institution.ibv_connection_id
    );
    if (institutionWithConnection && institutionWithConnection[0]) {
      ibvConnection = {
        id: institutionWithConnection[0].ibv_connection_id,
        vendor_connection_id:
          institutionWithConnection[0].ibv_vendor_connection_id,
        status: institutionWithConnection[0].ibv_connection_status,
        vendor_name: vendorName,
      };
    }
    return ibvConnection;
  }
};

export const isActiveConnection = (existingConnection) => {
  return ACTIVE_CONNECTION_STATUSES.indexOf(existingConnection?.status) !== -1;
};

export const productRequestFailedToProcess = (productRequest) => {
  return FAILURE_PRODUCT_REQUEST_STATUSES.includes(
    productRequest.ibv_report_status
  );
};

export const productRequestComplete = (productRequest) => {
  return productRequest.ibv_report_status === "complete";
};
