import {
  AKOYA_SET_WIDGET_CONFIGURATION,
  AKOYA_TOGGLE_LOADING,
  AKOYA_SET_ERROR,
} from "../constants/akoya-widget";

const initialState = {
  akoyaLink: null,
  showWidget: false,
  loading: false,
  error: null,
};

export const AkoyaReducer = (state = initialState, action) => {
  switch (action.type) {
    case AKOYA_TOGGLE_LOADING:
      return { ...state, ...action.payload };
    case AKOYA_SET_WIDGET_CONFIGURATION:
      return { ...state, ...{ akoyaLink: action.payload.akoya_link } };
    case AKOYA_SET_ERROR:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
