import YodleeWidget from "../vendor-widgets/yodlee-widget";
import SophtronWidget from "../vendor-widgets/sophtron-widget";
import FlinksWidget from "../vendor-widgets/flinks-widget";
import LoadingWidgetScreen from "./loading-widget-screen";
import { AkoyaWidget } from "../vendor-widgets/akoya-widget";
import UcwWidget from "../vendor-widgets/ucw-widget";

const NewConnectionFlow = (props) => {
  switch (props.vendor) {
    case "yodlee":
      return <YodleeWidget {...props} />;
    case "sophtron":
      return <SophtronWidget {...props} />;
    case "flinks":
      return <FlinksWidget {...props} />;
    case "akoya":
      return <AkoyaWidget {...props} />;
    case "ucw":
      return <UcwWidget {...props} />;
    default:
      return <LoadingWidgetScreen {...props} />;
  }
};

export default NewConnectionFlow;
