import {
  call,
  delay,
  getContext,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  cancelSophtronWidgetConfigPolling,
  setIsLoading,
  setSophtronWidgetConfigurationAction,
} from "../actions/sophtron-widget-actions";
import {
  closeModalsAndWidgets,
  logWidgetEvent,
  runProductRequestPolling,
  setCriticalIssueOccurred,
  setCurrentWidgetFlow,
  setDataPullProcessingTime,
  setSophtronConnection,
  setSwitchToNextVendor,
  setWidgetConfiguration,
  setWidgetFlow,
  stopProductRequestPolling,
  toggleProductRequestProcessing,
  toggleWidgetRender,
} from "../actions/widget-actions";
import {
  SOPHTRON_CONFIG_CANCEL_STATUS_POLLING,
  SOPHTRON_CONNECTION_UPDATE,
  SOPHTRON_HANDLE_SOPHTRON_WIDGET_CLOSE,
  SOPHTRON_HANDLE_SOPHTRON_WIDGET_ERROR,
  SOPHTRON_HANDLE_SOPHTRON_WIDGET_EVENT,
  SOPHTRON_HANDLE_SOPHTRON_WIDGET_SUCCESS,
  SOPHTRON_UNPROCESSABLE_ERROR_CODES,
  SOPHTRON_WIDGET_CONFIG_UPDATE,
  SOPHTRON_WIDGET_INIT,
  SOPHTRON_WIDGET_UPDATE_DELAY,
} from "../constants/sophtron-widget";
import { ApiErrorMessagesMapper } from "../services/api-client";
import { getWidgetConfiguration, isProductRequestFrozen } from "../shared/masamune-widget-utils";
import {
  findOrCreateSophtronConnection,
  updateSophtronConnectionRequest,
} from "../shared/sophtron-widget-utils";
import { CONNECTION_FREEZE } from "../constants/masamune-widget";

const vendor = "sophtron";

// Checks for already running pull to prevent duplicate pulls
const withProductRequestFreezeMiddleware = (worker) =>
  function* (action) {
    const globalConfig = yield select((state) => state.globalConfig);
    let requestFreeze = yield call(isProductRequestFrozen, globalConfig.productRequest?.product_request_id, globalConfig);

    if (requestFreeze) {
      yield put(setCurrentWidgetFlow(CONNECTION_FREEZE));
      return yield put(setIsLoading(false));
    }

    // If connection processing is not true, proceed with the original worker
    yield call(worker, action);
  };

function* processSophtronWidgetCallbackErrorWorker(action) {
  const { eventPayload } = action.payload;

  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;
  const rollbar = yield getContext("rollbar");
  const { onError } = globalConfig.clientCallbackFunctions;

  const connectionUpdateParams = {
    connectionId: globalConfig.sophtronConnection?.id,
    productRequestId: globalConfig.productRequest?.product_request_id,
    edgeClientId: globalConfig.edgeClientId,
    vendorInstitutionId: eventPayload?.providerId,
    status: "login_failed",
    accessToken: globalConfig.accessToken,
    vendorResponse: eventPayload,
  };

  try {
    yield put(logWidgetEvent({ eventPayload, accessToken, vendor }));
    yield put(setIsLoading(true));
    yield call(updateSophtronConnectionRequest, connectionUpdateParams);

    if (
      SOPHTRON_UNPROCESSABLE_ERROR_CODES.indexOf(
        eventPayload["additionalStatus"]
      ) !== -1
    ) {
      rollbar.error(
        "Failed to connect Sophtron with unprocessable code",
        eventPayload["additionalStatus"],
        {
          params: connectionUpdateParams,
        }
      );

      yield delay(2000);

      yield put(setSwitchToNextVendor());
      yield put(setCriticalIssueOccurred());
      yield call(
        onError,
        ApiErrorMessagesMapper(eventPayload, {
          isLastVendor: globalConfig.isLastVendor,
        })
      );
      yield put(setIsLoading(false));
      yield put(setWidgetConfiguration({ showModal: true }));
    }
  } catch (e) {
    rollbar.error("Failed to process error callback for Sophtron", e, {
      params: connectionUpdateParams,
    });

    yield call(
      onError,
      ApiErrorMessagesMapper(e, { isLastVendor: globalConfig.isLastVendor })
    );

    /// Unmount on authentication error
    if (e?.cause?.statusCode === 401) {
      return yield put(toggleWidgetRender());
    }
    /// Switch to next on bad requests and server errors
    if (
      e?.cause?.statusCode === 500 ||
      e?.cause?.statusCode === 422 ||
      e?.cause?.statusCode === 400
    ) {
      yield put(setSwitchToNextVendor());
      return yield put(setCriticalIssueOccurred());
    }
    /// Switch to the next vendor if the Sophtron code itself trows some errors
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
    yield put(setWidgetConfiguration({ showModal: true }));
  }
}

function* processSophtronWidgetCallbackSuccessWorker(action) {
  const { vendorResponse, connectionId, productRequestId } = action.payload;
  const {
    accessToken,
    clientCallbackFunctions: { onError, onSuccess },
    edgeClientId,
    isLastVendor,
    productRequest,
  } = yield select((state) => state.globalConfig);
  const rollbar = yield getContext("rollbar");

  yield put(
    logWidgetEvent({ eventPayload: vendorResponse, accessToken, vendor })
  );

  const connectionUpdateParams = {
    connectionId,
    productRequestId,
    edgeClientId,
    accessToken,
    vendorResponse,
    vendorInstitutionId: vendorResponse?.providerId,
    status: "login_success",
  };

  try {
    yield put(setDataPullProcessingTime());
    yield call(updateSophtronConnectionRequest, connectionUpdateParams);
    yield put(toggleProductRequestProcessing(true));
    yield put(setWidgetFlow());
    // Start product request polling
    yield put(runProductRequestPolling({ productRequestId, accessToken }));
  } catch (e) {
    rollbar.error("Failed to process success callback for Sophtron", e, {
      params: connectionUpdateParams,
    });
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
    yield put(stopProductRequestPolling());
    yield put(cancelSophtronWidgetConfigPolling());
    yield call(onError, ApiErrorMessagesMapper(e, { isLastVendor }));
  }
  productRequest.ibv_report_status = "initiated"; // Workaround to fix the issue with the product request status on initial callback

  yield put(setIsLoading(false));
  yield call(onSuccess, productRequest); // Show the product request ID to the client
}

function* processSophtronWidgetCallbackEventWorker(action) {
  const { eventPayload } = action.payload;
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;

  yield put(logWidgetEvent({ eventPayload, accessToken, vendor }));
}

function* processSophtronWidgetCallbackCloseWorker(action) {
  const { eventPayload } = action.payload;
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;

  yield put(logWidgetEvent({ eventPayload, accessToken, vendor }));

  if (globalConfig?.widgetConfiguration?.features?.bankSelection) {
    yield put(closeModalsAndWidgets());
    yield put(
      setWidgetConfiguration({
        retryWithBankSelection: true,
        showModal: true,
      })
    );
  } else {
    yield put(setSwitchToNextVendor());
    yield put(setWidgetConfiguration({ showModal: true }));
  }
}

// Middleware-enhanced workers
const processSophtronWidgetCallbackCloseWorkerWithMiddleware = withProductRequestFreezeMiddleware(processSophtronWidgetCallbackCloseWorker);
const processSophtronWidgetCallbackSuccessWorkerWithMiddleware = withProductRequestFreezeMiddleware(processSophtronWidgetCallbackSuccessWorker);
const processSophtronWidgetCallbackEventWorkerWithMiddleware = withProductRequestFreezeMiddleware(processSophtronWidgetCallbackEventWorker);
const processSophtronWidgetCallbackErrorWorkerWithMiddleware = withProductRequestFreezeMiddleware(processSophtronWidgetCallbackErrorWorker);

// Watcher saga for Sophtron widget callbacks
function* watchSophtronWidgetCallbacks() {
  yield takeEvery(
    SOPHTRON_HANDLE_SOPHTRON_WIDGET_CLOSE,
    processSophtronWidgetCallbackCloseWorkerWithMiddleware
  );
  yield takeEvery(
    SOPHTRON_HANDLE_SOPHTRON_WIDGET_SUCCESS,
    processSophtronWidgetCallbackSuccessWorkerWithMiddleware
  );
  yield takeEvery(
    SOPHTRON_HANDLE_SOPHTRON_WIDGET_EVENT,
    processSophtronWidgetCallbackEventWorkerWithMiddleware
  );
  yield takeEvery(
    SOPHTRON_HANDLE_SOPHTRON_WIDGET_ERROR,
    processSophtronWidgetCallbackErrorWorkerWithMiddleware
  );
}

function* handleSophtronConnectionUpdateWorker(action) {
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;
  const edgeClientId = globalConfig.edgeClientId;

  const connectionUpdateParams = {
    ...action.payload,
    accessToken,
    edgeClientId,
  };
  yield put(setIsLoading(true));
  yield call(updateSophtronConnectionRequest, connectionUpdateParams);
}

function* watchSophtronConnectionUpdate() {
  yield takeEvery(
    SOPHTRON_CONNECTION_UPDATE,
    handleSophtronConnectionUpdateWorker
  );
}

function* sophtronConfigurationUpdateWorker(action) {
  const { connectionId } = action.payload;
  const globalConfig = yield select((state) => state.globalConfig);
  const accessToken = globalConfig.accessToken;

  const widgetConfig = yield call(getWidgetConfiguration, {
    vendor,
    connectionId,
    accessToken,
  });

  yield put(setSophtronWidgetConfigurationAction(widgetConfig));
}

function* watchSophtronConfigurationUpdate() {
  yield takeLatest(
    SOPHTRON_WIDGET_CONFIG_UPDATE,
    sophtronConfigurationUpdateWorker
  );
}

export function* updateSophtronConfigurationWorker() {
  // race effect takes the first who completed in parallel

  yield race({
    // Start the polling
    task: call(pollSophtronWidgetConfigurationWorker),
    // Start a take effect waiting for the cancel action.
    cancel: take(SOPHTRON_CONFIG_CANCEL_STATUS_POLLING),
  });
}

function* pollSophtronWidgetConfigurationWorker() {
  while (true) {
    const globalConfig = yield select((state) => state.globalConfig);
    const rollbar = yield getContext("rollbar");
    const currentProvider = globalConfig.currentProvider;
    const { onError } = globalConfig.clientCallbackFunctions;

    const configParams = {
      vendor,
      connectionId: globalConfig.sophtronConnection?.id,
      accessToken: globalConfig.accessToken,
    };
    try {
      yield delay(SOPHTRON_WIDGET_UPDATE_DELAY);

      if (currentProvider !== vendor) {
        yield put(cancelSophtronWidgetConfigPolling()); // Cancel polling if vendor has changed
      }
      const widgetConfig = yield call(getWidgetConfiguration, configParams);

      yield put(setSophtronWidgetConfigurationAction(widgetConfig));
    } catch (e) {
      rollbar.error("Failed to perform widget config update for Sophtron", e, {
        params: configParams,
      });
      yield call(
        onError,
        ApiErrorMessagesMapper(e, { isLastVendor: globalConfig.isLastVendor })
      );
      yield put(toggleWidgetRender());
      yield put(cancelSophtronWidgetConfigPolling());
    }
  }
}

function* handleSophtronWidgetInitWorker(action) {
  const { productRequestId, institutionId } = action.payload;

  const globalConfig = yield select((state) => state.globalConfig);
  const rollbar = yield getContext("rollbar");
  const { onError } = globalConfig.clientCallbackFunctions;

  const accessToken = globalConfig.accessToken;
  const edgeClientId = globalConfig.edgeClientId;

  try {
    yield put(setIsLoading(true));
    const connection = yield call(findOrCreateSophtronConnection, {
      productRequestId,
      institutionId,
      edgeClientId,
      accessToken,
    });
    yield put(setSophtronConnection(connection));
    const widgetConfig = yield call(getWidgetConfiguration, {
      vendor,
      connectionId: connection.id,
      accessToken,
    });
    yield put(setSophtronWidgetConfigurationAction(widgetConfig));
    yield put(setIsLoading(false));

    // Run widget configuration polling after widget configuration was set
    yield call(updateSophtronConfigurationWorker);
  } catch (e) {
    rollbar.error("Failed to init Sophtron widget", e, {
      params: action.payload,
    });

    yield call(
      onError,
      ApiErrorMessagesMapper(e, { isLastVendor: globalConfig.isLastVendor })
    );

    /// Unmount on authentication error
    if (e?.cause?.statusCode === 401) {
      return yield put(toggleWidgetRender());
    }
    /// Switch to next on bad requests and server errors
    if (
      e?.cause?.statusCode === 500 ||
      e?.cause?.statusCode === 422 ||
      e?.cause?.statusCode === 400
    ) {
      yield put(setSwitchToNextVendor());
      return yield put(setCriticalIssueOccurred());
    }
    /// Switch to the next vendor if the Sophtron code itself trows some errors
    yield put(setSwitchToNextVendor());
    yield put(setCriticalIssueOccurred());
    yield put(setIsLoading(false));
  }
}

function* watchSophtronWidgetInit() {
  yield takeEvery(SOPHTRON_WIDGET_INIT, handleSophtronWidgetInitWorker);
}

export default function* sophtronWidgetSagas() {
  yield spawn(watchSophtronWidgetCallbacks);
  yield spawn(watchSophtronConnectionUpdate);
  yield spawn(watchSophtronConfigurationUpdate);
  yield spawn(watchSophtronWidgetInit);
}
